import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CreateListService } from '../../../services/CreateList.service';
import { map } from 'rxjs/operators';
import { ListDataService } from 'src/app/shared/services/list-data.service';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';

@Component({
  selector: 'app-select-columns',
  templateUrl: './select-columns.component.html',
  styleUrls: ['./select-columns.component.css']
})
export class SelectColumnsComponent implements OnInit {

  TabNames = {
    prospect: 'prospect',
    wba: 'wba',
    contact: 'contact',
  }

  public activeTab: any = this.TabNames.prospect;
  excludedColumns: any[] = [];
  ListID: any;
  //@Output() ColumnsEvent = new EventEmitter<any>();
  ListDetailData: any = {};
  tabSearch: any = {}
  _navigationPath: NavigationPath;
  public search: string = '';
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  mode: string = 'create';

  @Output() stepperEvent = new EventEmitter<string>();

  public errorList: any[] = []
  selectedColumns: any[] = [];
  getListId: any;
  apiCallorNot: boolean = false;
  columnNames1: any = {}

  columnNames = {
    [this.TabNames.prospect]: [],
    [this.TabNames.wba]: [
      {
        "columnName": "WhiteBoardName",
        "isVisible": true
      },
      {
        "columnName": "Revenue",
        "isVisible": true
      },
      {
        "columnName": "PhoneNumber",
        "isVisible": false
      }
    ],
    [this.TabNames.contact]: [
      {
        "columnName": "ContactName",
        "isVisible": true
      },
      {
        "columnName": "Revenue",
        "isVisible": true
      },
      {
        "columnName": "PhoneNumber",
        "isVisible": false
      }
    ],
  }
  showError: boolean = false;
  errorMessage: any;
  isOneColumnSelected: boolean = false;

  constructor(
    private _createListService: CreateListService,
    private _listDataService: ListDataService,
    private _router: Router,
    public activeModal: NgbActiveModal,
    private _ProspectListService: ProspectListService

  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this.ListID = sessionStorage.getItem('listId')
    //this.ListID = Number(this._listDataService.getListId().value)
    this.getQueryById();
    //this.ListDetailData = this._listDataService.getListDetailedData().value

    //this.ListDetailData = this._listDataService.getListDetailedData().value

    this.getSelectedColumns(this.ListID);
    for (let item in this.TabNames) {
      this.tabSearch[item] = '';
    }

    this.columnNames1 = { ...this.columnNames };
  }
  getSelectedColumns(listId: number) {
    this._createListService.getSelectedColumns(listId).subscribe((resp: any) => {
      if (resp) {
        // checking checked column from columnList
        resp.columnList.forEach((x: any) => {
          if (x.isVisible) {
            this.isOneColumnSelected = false;
          }
        })
        // get checked/unchecked from columnList    
        this.excludedColumns = resp.columnList
        this.columnNames[this.TabNames.prospect] = resp.columnList;
      }
    },
      (error: any) => {

      })
  }

  //list detail method
  getQueryById() {
    this._ProspectListService.getQueryById(this.ListID).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
        }
      }),
      error: (() => {

      })
    })
  }

  //Select and Unselect a prospect from list.
  excludeColumnsFromList(item: any) {
    item.isVisible = !item.isVisible;

    for (var i = 0; i < this.columnNames[this.TabNames.prospect].length; i++) {
      if (this.columnNames[this.TabNames.prospect][i] == item.columnName) {
        this.columnNames[this.TabNames.prospect] = this.columnNames[this.TabNames.prospect].map(i => {
          return {
            columnName: item.columnName,
            isVisible: item.isVisible
          }
        });
      }
    }
    this.excludedColumns = this.columnNames[this.TabNames.prospect]
    //this.ColumnsEvent.emit(this.columnNames[this.TabNames.prospect]);
  }

  saveSelectedColumns(redirectType: string) {
    let data = {
      "listId": this.ListID,
      "columnList": this.excludedColumns
    }
    let finishedValues = data.columnList.filter(n => n.isVisible).length;
    if (finishedValues == 0) {
      this.isOneColumnSelected = true;
    } else {
      this.errorList = []
      this._createListService.saveSelectedColumns(data).subscribe((resp: any) => {
        if (resp) {
          if (redirectType == 'next')
            this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'share-prospects');
          else {
            this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'select-prospects');

          }
        }
      },
        (err: any) => {
          if (err.status !== 200) {
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorMessage = "Error : " + arr[0].value[0];
              this.errorList.push(this.errorMessage);
            } else {

              this.showError = true;
              this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
              this.errorList.push(this.errorMessage);
            }
          }
        });
    }
  }

  searchColumns() {
    this.tabSearch[this.activeTab] = this.search;
    this.columnNames[this.activeTab] = this.columnNames1[this.activeTab].filter((obj: any) => obj.ColumnName.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) != -1)
  }

  tabChange(tabName: string) {
    this.activeTab = tabName;
    this.search = this.tabSearch[tabName];
    if (this.search) this.searchColumns();
  }

  backButton() {
    //this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'select-prospects');
    this.mode = sessionStorage.getItem('editMode')!;
    if (this.mode == null) {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateListUrl());
      sessionStorage.removeItem('editMode')
    }
    else {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditListUrl());
      sessionStorage.removeItem('editMode')
    }
  }

  // closeModel() {
  //   this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
  // }
  closeModel() {
    this.errorList = []
    let data = {
      listId: this.ListID,
      columnList: this.excludedColumns
    }
    data.columnList.forEach((element: any) => {
      if (element.isVisible == true) {
        this.apiCallorNot = true;
        return;
      }
    })
    if (this.apiCallorNot == true) {
      this._createListService.saveSelectedColumns(data).subscribe((resp: any) => {
        if (resp) {
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
      })
    } else {

      this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');

    }

  }

  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }
}
