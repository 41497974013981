import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ListDataService } from 'src/app/shared/services/list-data.service';
import { CreateListService } from '../../../services/CreateList.service';

@Component({
  selector: 'app-share-contact-user-list',
  templateUrl: './share-contact-user-list.component.html',
  styleUrls: ['./share-contact-user-list.component.css']
})
export class ShareContactUserListComponent implements OnInit {
  userIds: any[] = [];
  public errorList: any[] = []
  ListID: any;
  ListDetailData: any = {};
  getListId: any;
  _navigationPath: NavigationPath;
  shareUsers: any[] = [];
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  userIdsByLevelBlank: any[] = [];
  apiCallorNot: boolean = false;
  mode: string = 'create';
  constructor(
    private _createListService: CreateListService,
    private _listDataService: ListDataService,
    private _router: Router,
    public activeModal: NgbActiveModal,
    private _ProspectListService: ProspectListService
  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {

    this.ListID = sessionStorage.getItem('listId')
    //this.ListID = Number(this._listDataService.getListId().value)
    this.getQueryById();
    // this.ListDetailData = this._listDataService.getListDetailedData().value
    this.getUsersByList(this.ListID);

  }

  getUsersByList(ListID: any) {
    this._createListService.getSelectedUsers(ListID).subscribe((resp: any) => {
      if (resp) {
        this.userIds = resp.listSharing.users;
      }
    },
      (error: any) => {

      })
  }

  //list detail method
  getQueryById() {
    this._ProspectListService.getContactById(this.ListID).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listContactFilter;
        }
      }),
      error: (() => {

      })
    })
  }

  //Select and Unselect users for sharing list.
  shareListToUsers(item: any) {
    item.isShared = !item.isShared;
    for (var i = 0; i < this.userIds.length; i++) {
      if (this.userIds[i] == item.userId) {
        this.userIds = this.userIds.map(i => {
          return {
            userId: item.userId,
            isShared: item.isShared

          }

        });
      }
    }
    // this.UsersEvent.emit(this.userIds);
  }

  //save the users
  saveSharedUsers() {
    this.errorList = []
    let data = {
      "listId": this.ListID,
      "users": this.userIds
    }
    let finishedValues = data.users.filter(n => n.isShared).length;
    if (finishedValues == 0) {
      // this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'split-list');
      //calling publish api
      this._createListService.publishContactList(this.ListID).subscribe((resp: any) => {
        if (resp) {

          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
      })
    }
    else {

      this._createListService.saveSharedUsers(data).subscribe((resp: any) => {
        if (resp) {
          //this.ListID = resp;
          //calling publish api
          this._createListService.publishContactList(this.ListID).subscribe((resp: any) => {
            if (resp) {

              this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
            }
          })
        }
      },
        (error: any) => {
          let obj = error.error.errors;
          if (obj) {
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = arr;
            return
          }
        }
      )

    }
  }

  backButton() {
    this.mode = sessionStorage.getItem('editMode')!;
    if (this.mode == null) {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateContactListUrl());
      sessionStorage.removeItem('editMode')
    } else {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditContactListUrl());
      sessionStorage.removeItem('editMode')
    }
  }

  closeModel() {
    this.errorList = []
    let data = {
      listId: this.ListID,
      users: this.userIds
    }
    data.users.forEach((element: any) => {
      if (element.isShared == true) {
        this.apiCallorNot = true;
        return;
      }
    })
    if (this.apiCallorNot == true) {
      this._createListService.saveSharedUsers(data).subscribe((resp: any) => {
        if (resp) {
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
      })
    } else {

      this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');

    }
  }

  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }
}
