import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { PaginatorModel } from 'src/app/shared/class/paginatorModel';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ListDataService } from 'src/app/shared/services/list-data.service';
import { CreateListService } from '../../../services/CreateList.service';

@Component({
  selector: 'app-select-prospects',
  templateUrl: './select-prospects.component.html',
  styleUrls: ['./select-prospects.component.css']
})
export class SelectProspectsComponent implements OnInit {
  ListID: any;
  ListDetailData: any = {};
  OverlappedProspectsList: any;
  excludedProspectsIds: any[] = [];
  errorList: any[] = []
  isAllProspectSelected: boolean = false;
  _navigationPath: NavigationPath;
  errorMessage: any;
  showError: boolean = false;
  getListId: any;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  mode: string = 'create';
  isExpand: boolean = false;
  ListPopUpDetailData: any;
  list: any;
  listpopProspectId: any;
  isPanelOpen: boolean = false;
  _paginator: PaginatorModel;
  contactList: any = [];
  searchText: string = '';
  pageNo: number = 1;
  pageSize: number = 10;
  $searchingText = new Subject<void>();
  first: number = 0;
  currentpage: number = 0;
  public skip = 0;


  constructor(
    private _createListService: CreateListService,
    private _router: Router,
    public activeModal: NgbActiveModal,
    private _ProspectListService: ProspectListService,
    public datepipe: DatePipe,
  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._paginator = new PaginatorModel();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.selectProspectPaginates();
    });
  }

  ngOnInit(): void {
    this.ListID = sessionStorage.getItem('listId')
    this.selectProspectPaginates();
    //this.getOverlappedProspects(this.ListID);
    this.getQueryById();


    // this.ListID.forEach((ListID: { isExpand: boolean; }) => {
    //   ListID.isExpand = false;
    // });
  }



  getOverlappedProspects(listId: number) {
    this._createListService.getOverlappedProspects(listId).subscribe((resp: any) => {
      if (resp) {
        this.OverlappedProspectsList = resp.overlapList;
        this.OverlappedProspectsList.forEach((x: any) => {
          if (x.isExclude)
            this.excludedProspectsIds.push(x.prospectId);
        });
        if (this.OverlappedProspectsList.length == this.excludedProspectsIds.length) {
          this.isAllProspectSelected = true;
        }
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }

  //get list details By id
  getQueryById() {
    this._ProspectListService.getQueryById(this.ListID).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
          sessionStorage.setItem("prospectId", this.ListDetailData.prospectId)
        }
      }),
      error: (() => {

      })
    })
  }

  //Select and Unselect a prospect from list.
  excludeProspectsFromList(item: any) {
    item.isExclude = !item.isExclude;
    if (item.isExclude == true) {
      this.excludedProspectsIds.push(item.prospectId);
    }
    if (item.isExclude == false) {
      for (var i = 0; i < this.OverlappedProspectsList.length; i++) {
        if (this.excludedProspectsIds[i] == item.prospectId) {
          this.excludedProspectsIds.splice(i, 1);
        }
      }
    }
    // this.ProspectEvent.emit(this.excludedProspectsIds);
  }

  saveOverlappedProspects() {
    this.errorList = []
    let data = {
      "listId": this.ListID,
      "excludedProspect": this.excludedProspectsIds
    }
    if (data.excludedProspect.length > 0) {
      this._createListService.saveOverlappedProspects(data).subscribe((resp: any) => {
        if (resp) {
          //redirect to column page
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'select-columns');
        }
      },
        (error: any) => {

        }
      )
    }
    //redirect to column page no api call if no prospect is selected
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'select-columns');

  }

  //Select and Unselect all prospect in list.
  changeAllSelection() {
    this.isAllProspectSelected = !this.isAllProspectSelected;
    if (this.isAllProspectSelected) {
      this.OverlappedProspectsList.forEach((x: any) => {
        x.isExclude = this.isAllProspectSelected;
        this.excludedProspectsIds.push(x.prospectId);
      });
      //sending data to stepper
      // this.ProspectEvent.emit(this.excludedProspectsIds);
    }
    else {
      this.OverlappedProspectsList.forEach((x: any) => {
        x.isExclude = this.isAllProspectSelected;
        this.excludedProspectsIds.splice(x, 1);
      });
    }
  }

  backButton() {
    this.mode = sessionStorage.getItem('editMode')!;
    if (this.mode == null) {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateListUrl());
      sessionStorage.removeItem('editMode')
    }
    else {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditListUrl());
      sessionStorage.removeItem('editMode')
    }
  }

  //get Pop up Detail
  getListPopUpDetail() {
    let data = {
      "listId": this.ListID,
      "prospectId": this.listpopProspectId
    }
    this._createListService.getListPopUpDetail(data).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListPopUpDetailData = resp.existList;
          // this.ListPopUpDetailData = [...ListPopUpDetailData1];
          // this.ListPopUpDetailData = resp.existList;
        }
      }),
      error: (() => {

      })
    })
  }

  closeModel() {
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
  }

  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }
  classAppliedLeft = true;
  classAppliedRight = true;
  btnclass1 = true;


  //for pop up open
  isEXP(data: any) {
    let status = data.isExpand
    this.OverlappedProspectsList.forEach((element: any) => {
      element.isExpand = false;
    });
    this.listpopProspectId = data.prospectId;
    data.isExpand = !status
    this.isPanelOpen = !this.isPanelOpen
    if (data.isExpand == true) {
      this.getListPopUpDetail();
    }
  }

  //paging in left panel list
  onPageChange(event: any) {
    this.currentpage = event.page + 1;
    this.first = (this.currentpage - 1) * 20;
    //this.first = 0;
    this.pageNo = event.page + 1;
    this.pageSize = event.rows
    this.selectProspectPaginates();
    //this.$searchingText.next();
  }

  selectProspectPaginates() {
    var data = {
      "listId": this.ListID,
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "sortDir": "asc",
      "sortCol": "prospectName",
      "searchText": this.searchText
    };
    if (data) {
      this._createListService.selectProspectPagination(data).subscribe((resp) => {
        if (resp) {
          this._paginator.totalRecords = resp.totalRecord;
          this.OverlappedProspectsList = resp.items[0].overlapList;
        }
      });
    }
  }

  // searching records
  filterSearch() {

    this.skip = 0;
    this.first = 0;
    this.pageNo = 1;
    //this.selectProspectPaginates()
    this.$searchingText.next();
  }
}
