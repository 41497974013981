//////////////////////////////////////creating json after criteria selected========================

export class saveSelectedCriteria {
  listFilter!: filtersOld
}
export class filtersOld {
  id: number = 0;
  listName!: string
  ListType!: string
  SaveAs!: string
  expirationDate?: any
  TemplateId!: number
  filterJson!: string
  rowCount!: number;
  prospectId!: any[]
}

/////////////////////prospect list 

export type root = prospectCreateList[]

export class prospectCreateList {
  label: string = ''
  value: string = ''
  items: Item[] = [];
}
export class reasonLostWbaList {
  label: string = ''
  value: string = ''
  items: Item[] = [];
}


export class Item {
  label: string = ''
  value: string = ''
}
