import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { PaginatorModel } from 'src/app/shared/class/paginatorModel';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { ManageListService } from '../../services/ManageList.service';
import { UserListPreviewComponent } from '../user-list-preview/user-list-preview.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataBindingDirective, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { Title } from '@angular/platform-browser';
import { UserManagementService } from 'src/app/features/user-management/services/user-management.service';
import { GridColumnSelectionModalComponent } from '../grid-column-selection-modal/grid-column-selection-modal.component';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';

@Component({
  selector: 'app-manage-list',
  templateUrl: './manage-list.component.html',
  styleUrls: ['./manage-list.component.css'],
})
export class ManageListComponent implements OnInit {
  listFilterData = JSON.parse(sessionStorage.getItem('listProspectFilterStorage')!);
  manageList: any;
  masterManageList: any;
  id: any;
  column: any;
  isAllProspectSelected: boolean = false;
  selectedProspectCount = 0;
  _navigationPath: NavigationPath;
  _paginator: PaginatorModel;
  searchText: string = '';
  filterDropdownList: any[] = [];
  totalRecords: number = 0;
  filterListName: any;
  selectedListName: string = 'All';
  showError: boolean = false;
  errorList: any[] = [];
  errorMessage: string = '';
  defaultList: any;
  selectedListId: any;
  toggleListButton: any = 'Prospect';
  highlightedRow: number = 0;
  isFilter: boolean = false;
  searchGrid: string = '';
  $searchingText = new Subject<void>();
  public multiple = false;
  public allowUnsort = true;
  showDynamicCount: boolean = false;
  allCols: any;
  orderedCols: any[] = [];
  columnOrder: string[] = [];
  columnsOfList: any[] = [];
  @ViewChild('grid', { static: false }) public grid!: GridComponent;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public buttonCount = 5;
  public sizes = [10, 25, 50];
  pageSize = this.listFilterData != null ? this.listFilterData.pageSize : 100;
  totalCount = this.listFilterData != null ? this.listFilterData.totalCount : 0;
  pageNo: number = this.listFilterData != null ? this.listFilterData.pageNumber : 1;
  skip = this.listFilterData != null ? (this.listFilterData.pageNumber - 1) * this.listFilterData.pageSize : 0;
  FinalProspectList: any = [];
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  public sort: SortDescriptor[] = [
    {
      field: this.listFilterData != null ? this.listFilterData.sortCol : 'listName',
      dir: this.listFilterData != null ? this.listFilterData.sortDir : 'asc',
    },
  ];
  masterProspectList: any = [];
  selectedParPageOption: number = 0;
  _confirmBox: ConfirmBox;
  _confirmBox1: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  listentityType: any;
  saveAsSelectedId: string = '1';
  unAthorizesd: any = '';
  filterData: any;
  statusOption: any;
  listType: any;
  gridFilterForStatus: any;
  gridFilterArrayStatus: any[] = [];
  gridFilterForlistEntityType: any;
  gridFilterArrayListEntityType: any[] = [];
  userListTotalCount: any;
  isInValidNumber: boolean = false;
  isValidUser: boolean = false;
  //saveAsList: any[] = [];
  selectResetArr: any = {};
  listFilterJsonPayload: any
  columnWidthMaster: any = {}
  ColumnOrderMaster: any = []
  columnsOfListMasterData: any = [];
  reordered: boolean = false;
  removeFilterForhideColumn: boolean = false;
  filterDataEmpty = "";
  userList: any;
  constructor(
    private _router: Router,
    private router: Router,
    private _loaderService: LoaderService,
    private _manageListService: ManageListService,
    public datepipe: DatePipe,
    private _customNotifireService: CustomNotifireService,
    private modalService: NgbModal,
    public _activeModal: NgbActiveModal,
    private _selectedHeaderService: SelectedHeaderService,
    private _preventBack: PreventBackButtonService,
    public profileInfoService: ProfileInfoService,
    private titleService: Title,
    private userManagementService: UserManagementService,
    private _addWBAService: WhiteBoardActivityService,
  ) {
    this._navigationPath = new NavigationPath();
    this._paginator = new PaginatorModel();
    this._confirmBox = new ConfirmBox();
    this._confirmBox1 = new ConfirmBox();
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getManageList('All');
    });
  }

  @HostListener('document:keypress', ['$event'])
  startSearch(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.onFilter()
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('Lists');
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this._preventBack.preventBackButton(); //method to prevent browser back button
    this._customNotifireService.setActiveComponent('List');
    this.getFilterDropdown();
    this.userRoleChecked();
    this.statusOption = ['Draft', 'Published', 'System-Default', 'Template', 'Marketing-List']
    //if grid filter applied then kendo filter is opened on load
    this.isFilter = true;
    this.listType = ['Prospect', 'Contact', 'WBA']
    // if (this.listFilterData) {
    //   if (this.listFilterData.gridFilterJson != "") {
    //     this.isFilter = true;
    //   }
    // }
    // this.getDynamicColumnList();
    this.getUserList();
  }
  //searching in grid columns
  public filterChange(filter: CompositeFilterDescriptor): void {

    this.selectResetArr["columnFilter"] = true;
    filter.filters.map((obj: any) => {
      if (obj.field == 'listType') {
        if (obj.value == 'true') {
          obj.value = 'Static'
        }
        if (obj.value == 'false') {
          obj.value = 'Dynamic'
        }
      }
    })
    // if (filter.filters.length > 0) {
    //   filter.filters.forEach((x: any) => {
    //     let tempgridFilter = x;
    //     this.gridFilter = { ...tempgridFilter };
    //   })
    // }
    // else {
    //   this.gridFilter = { ...filter };
    // }
    this.gridFilter = { ...filter };
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;

    if (this.gridFilter.filters.length > 0) {
      var listEntityTypeIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "listEntityType")
      if (listEntityTypeIndex > -1 && this.gridFilterArrayListEntityType.length == 0)
        this.gridFilter.filters.splice(listEntityTypeIndex, 1);
      if (listEntityTypeIndex < 0) this.gridFilterArrayListEntityType = [];

      var creationStatusIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "creationStatus")
      if (creationStatusIndex > -1 && this.gridFilterArrayStatus.length == 0)
        this.gridFilter.filters.splice(creationStatusIndex, 1)
      if (creationStatusIndex < 0) this.gridFilterArrayStatus = [];

    }
    if (!this.gridFilter?.filters.length) {
      this.gridFilterArrayListEntityType = [];
      this.gridFilterArrayStatus = [];
    }

    if (this.gridFilterArrayListEntityType != undefined && this.gridFilterArrayListEntityType.length > 0)
      this.onlistEntityChange(this.gridFilterArrayListEntityType);

    if (this.gridFilterArrayStatus != undefined && this.gridFilterArrayStatus.length > 0)
      this.onlistStatusChange(this.gridFilterArrayStatus);
    // this.$searchingText.next();
    this.getManageList("", true);
  }

  prospectPaginate(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.FinalProspectList.total
    //calling api
    this.getManageList(this.selectedListId, true);
  }

  //To open 'Add Prospect' Modal
  openViewUserListModal(item: any) {
    sessionStorage.setItem("sharedUser", JSON.stringify(item.sharedUser));
    this.highlightedRow = 0;
    const modalRef = this.modalService.open(UserListPreviewComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'userlistpreview',
    });
    modalRef.closed.subscribe((resp: any) => {
      this.isAllProspectSelected = false
      this.selectedProspectCount = 0;
    });
  }

  //searching on all columns
  public onFilter(): void {
    let data = JSON.parse(sessionStorage.getItem('listProspectFilterStorage')!);
    if (this.searchGrid === "" && data?.searchText === "") {
      return
    }
    else {
      this.pageNo = 1;
      this.skip = 0;
      this.totalCount = 0;
      this.getManageList('All', true);
    }
  }

  //for grid column on listStatus Change
  public onlistStatusChange(event: any): void {
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "creationStatus")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "creationStatus",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "creationStatus",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    // this.$searchingText.next();
    this.getManageList("", true);
  }


  //for grid column on listEntity Change
  public onlistEntityChange(event: any): void {
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "listEntityType")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "listEntityType",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "listEntityType",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    // this.$searchingText.next();
    this.getManageList("", true);
  }

  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
    this.filterData.searchText = this.searchGrid;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.selectResetArr["sorting"] = true;
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = this.FinalProspectList.total
    this.sort = sort;
    this.getManageList(this.selectedListId, true);
  }

  getFilterDropdown() {
    this._manageListService.getFilterDropdown().subscribe((resp) => {
      this.filterDropdownList = resp.manageListType;
      if (sessionStorage.getItem('selectedListId')) this.saveAsSelectedId = sessionStorage.getItem('selectedListId')!;
      this.selectedListName = this.filterDropdownList.find((res: any) => res.id == this.saveAsSelectedId)?.listName;
      this.getDynamicColumnList();
      // if (this.listFilterData) {
      //   this.getManageListUsingFilterStorage(this.listFilterData)
      // } else {
      //   this.getManageList('All');
      // }
    },
      ((err: any) => {
        if (err.status == 403 && this.unAthorizesd) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
        }
      })
    );
  }
  getManageList(data: any, saveInDB = false) {
    this.convertDateStringInFormatting(this.gridFilter);
    data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "saveAs": this.selectedListName,
      "totalCount": this.totalCount,
      "sortDir": this.sort[0].dir,
      "sortCol": this.sort[0].field,
      "searchText": this.searchGrid,
      "listEntityType": "PROSPECT",
      "gridFilterJson": this.gridFilter.filters != null && this.gridFilter.filters.length > 0 ? JSON.stringify(this.gridFilter) : ""
    }

    sessionStorage.setItem('listProspectFilterStorage', JSON.stringify(data));
    if (saveInDB) {
      this.listFilterJsonPayload = JSON.stringify(data);
      this.saveUserMasterGridColumn();
    }
    this._manageListService.managePagination(data).subscribe(
      (resp) => {
        data.totalCount = resp.totalRecord;
        sessionStorage.setItem('listProspectFilterStorage', JSON.stringify(data));
        this._loaderService.show();
        this.gridFilter = this.convertFormattingInDateString(this.gridFilter);
        if (resp && resp.items) {
          for (var i = 0; i < resp.items.length; i++) {
            if (i == 0) {
              this.masterManageList = resp.items[i].manageList;
            }
          }
          this.masterManageList = resp.items[0].manageList;
          if (this.masterManageList) {
            this.masterProspectList = this.masterManageList;
            this.FinalProspectList = {
              data: this.masterProspectList,
              total: resp.totalRecord,
            };
            this._loaderService.hide();
          }
        }
      },
      (err: any) => {
        this.convertFormattingInDateString(this.gridFilter);
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }
  getManageListUsingFilterStorage(data: any) {
    // this.filterData = JSON.parse(sessionStorage.getItem('listProspectFilterStorage')!);
    this.filterData = JSON.parse(this.listFilterJsonPayload)!;
    this.filterData.totalCount = 0;
    this._manageListService.managePagination(this.filterData).subscribe(
      (resp) => {
        this._loaderService.show();
        if (resp) {
          this.masterManageList = resp.items[0].manageList;
          this.showError = false;
          if (this.masterManageList) {
            this.masterProspectList = resp.items[0].manageList;
            this.FinalProspectList = {
              data: this.masterProspectList,
              total: resp.totalRecord,
            };
            this.setDataUsingFilterStorage();
            this._loaderService.hide();
          }
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }

  setDataUsingFilterStorage() {
    // var filterData = JSON.parse(sessionStorage.getItem('listProspectFilterStorage')!)
    if (this.listFilterJsonPayload) {
      var filterData = JSON.parse(this.listFilterJsonPayload)!;
      if (filterData) {
        this.saveAsSelectedId = this.filterDropdownList.find((x: any) => x.listName.toLowerCase() == filterData.saveAs.toLowerCase())?.id;
        this.searchGrid = filterData.searchText;
        this.gridFilter = filterData.gridFilterJson ? this.convertFormattingInDateString(JSON.parse(filterData.gridFilterJson)) : "";
        this.selectedListName = filterData.saveAs;
        if (this.filterData.gridFilterJson) {
          this.gridFilterForStatus = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "creationStatus")[0]?.value;
          this.gridFilterArrayStatus = this.gridFilterForStatus?.split(',');
          this.gridFilterForlistEntityType = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "listEntityType")[0]?.value;
          this.gridFilterArrayListEntityType = this.gridFilterForlistEntityType?.split(',');
          // gridfilter for date value set here
          let gridFilterForexpirationDate = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "expirationDate")[0]?.value;
          let expirationDate = new Date(gridFilterForexpirationDate);
          let gridFilterForcreatedDate = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "createdDate")[0]?.value;
          let createdDate = new Date(gridFilterForcreatedDate);

          this.gridFilter.filters.map((obj: any) => {
            if (obj.field == 'expirationDate') {
              obj.value = expirationDate
            }
            if (obj.field == 'createdDate') {
              obj.value = createdDate
            }
          })
        }
      }
    }
  }
  onFilterDropdownChange(event: any) {
    this.selectedListName = event.target['options']
    [event.target['options'].selectedIndex].text;
    sessionStorage.setItem('selectedListId', event.target.value);
    this.totalRecords = 0;
    this.gridFilterArrayListEntityType = [];
    this.gridFilterArrayStatus = [];
    this.gridFilter = { logic: 'and', filters: [] };
    //this.getManageList(this.selectedListName);
    this.getDynamicColumnList();
    if (this.reordered) this.reloadComponent(true);
  }

  // calling for page number click
  managePaginate(value: any) {
    var data = {
      pageNo: value.page + 1,
      pageSize: value.rows,
      saveAsType: this.selectedListName,
    };
    if (data) {
      this._manageListService.managePagination(data).subscribe(
        (resp) => {
          this._loaderService.show();
          if (resp) {
            this._loaderService.hide();
            this._paginator.totalRecords = resp.totalRecord;
            this.masterManageList = resp.items[0].manageList;
          }
        },
        (error) => {
          this._loaderService.hide();
        }
      );
    }
  }

  // calling for page number click
  manageWhiteboardPaginate(filterType: string) {
    var data = {
      pageNo: 1,
      pageSize: this._paginator.rowsPerPageOptions[0],
      saveAsType: filterType,
    };
    this._manageListService.manageWhiteboardPagination(data).subscribe(
      (resp) => {
        this._loaderService.show();
        if (resp) {
          this.masterManageList = resp.items[0].manageList;
          if (this.masterManageList) {
            this.masterProspectList = resp.items[0].manageList;
            this.FinalProspectList = {
              data: this.masterProspectList,
              total: resp.totalRecord,
            };
            this._loaderService.hide();
          }
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }

  //get contact list
  getContactManageList(filterType: string) {
    var data = {
      pageNo: 1,
      pageSize: this._paginator.rowsPerPageOptions[0],
      saveAsType: filterType,
    };
    this._manageListService.manageContactPagination(data).subscribe(
      (resp) => {
        this._loaderService.show();
        if (resp) {
          this.masterManageList = resp.items[0].manageList;
          if (this.masterManageList) {
            this.masterProspectList = resp.items[0].manageList;
            this.FinalProspectList = {
              data: this.masterProspectList,
              total: resp.totalRecord,
            };
            this._loaderService.hide();
          }
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }
  filterSearch() {
    if (this.searchText) {
      this.manageList = this.masterManageList.filter((el: any) =>
        (el.firstName + ' ' + el.lastName)
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    } else {
      this.manageList = this.masterManageList;
    }
  }
  closeError() {
    this.showError = false;
    this.unAthorizesd = '';
  }

  //Navigate to 'prospect list' screen
  navigate(id: any) {
    sessionStorage.setItem('manageListId', id);
    this._router.navigateByUrl('/' + this._navigationPath.getProspectsUrl());
  }

  //Navigate to 'contact list' screen
  navigateContact(id: any) {
    sessionStorage.setItem('manageListId', id);
    this._router.navigateByUrl('/' + this._navigationPath.getContactsUrl());
  }

  navigatetoViewList(id: any) {
    sessionStorage.setItem('ListIdForDetails', id)
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + this._navigationPath.getprevieweListUrl());
  }

  navigatetoViewContactList(id: any) {
    sessionStorage.setItem('ListIdForDetails', id)
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + this._navigationPath.getprevieweContactListUrl());
  }

  navigatetoViewWbaList(id: any) {
    sessionStorage.setItem('ListIdForDetails', id)
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + this._navigationPath.getpreviewWbaListUrl());
  }

  navigateToList(item: any) {
    this._selectedHeaderService.setSelectedHeader('listManagement');
    if (item == '') {
      sessionStorage.setItem('manageListId', '0');
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateListUrl());
      // sessionStorage.removeItem('manageListId');
    }
    else {
      sessionStorage.setItem('manageListId', item.id);
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditListUrl());
    }
  }

  navigateToContactList(item: any) {

    this._selectedHeaderService.setSelectedHeader('listManagement');
    if (item == '') {
      sessionStorage.setItem('manageListId', '0');
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateContactListUrl());
      // sessionStorage.removeItem('manageListId');
    }
    else {
      sessionStorage.setItem('manageListId', item.id);
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditContactListUrl());
    }
  }

  navigateToWhiteBoardList(item: any) {

    this._selectedHeaderService.setSelectedHeader('listManagement');
    if (item == '') {
      sessionStorage.setItem('manageListId', '0');
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateWhiteBoardListUrl());
      // sessionStorage.removeItem('manageListId');
    }
    else {
      sessionStorage.setItem('manageListId', item.id);
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditWhiteBoardListUrl());
    }
  }

  exportListDataFile(listId: any, entityType: any): void {
    // let data: any = {
    //   listId: listId
    // };
    // this._manageListService.getExportList(data).pipe().subscribe(success => {
    //   if (success) {
    //     this.unAthorizesd = this._userMessages.exportListMessage;
    //     this._confirmBox.isVisible = true;
    //   }
    // })
    // this._manageListService.getExportList(data).pipe().subscribe((resp) => {
    //   let downloadLink = document.createElement('a');
    //   downloadLink.href = window.URL.createObjectURL(resp.body);
    //   // create name dynamically
    //   let cur_date = new Date();
    //   let listNameWithDate: any;
    //   listNameWithDate =
    //     listName + '_' +
    //     this.datepipe.transform(cur_date, 'MMddyyy_HHmm') +
    //     '.csv';

    //   downloadLink.setAttribute('download', listNameWithDate);
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   downloadLink.remove();
    // });

    const modalRef = this.modalService.open(GridColumnSelectionModalComponent, { modalDialogClass: 'listDialog' });
    modalRef.componentInstance.listId = listId;
    if (entityType == "Prospect") {
      let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');
      if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
    }
    if (entityType == "Contact") {
      let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');
      if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
      let indexcontactID = this.allCols.findIndex((x: any) => x.columnName == 'ContactID');
      if (indexcontactID > -1) this.allCols.splice(indexcontactID, 1);
    }
    if (entityType == "WBA") {
      let indexwbaId = this.allCols.findIndex((x: any) => x.columnName == 'WBAID');
      if (indexwbaId > -1) this.allCols.splice(indexwbaId, 1);
      let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');
      if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
    }
    modalRef.componentInstance.allCols = this.allCols;

    modalRef.result.then((result) => {

      if (result) {

        // Data sent back from modal
        let finalArr: any[] = []

        if (entityType == "Prospect") finalArr = ["ProspectID"];
        if (entityType == "Contact") finalArr = ["ProspectID", "ContactID"];
        if (entityType == "WBA") finalArr = ["WBAID", "ProspectID"];
        let arr: any = []
        arr = result.split(',');
        arr.forEach((x: any) => {
          finalArr.push(x);
        })
        if (result == '')
          finalArr = [];

        let data: any = {
          listId: listId,
          columnList: finalArr,
          entityType: entityType
        };
        // console.log(data);

        this._manageListService.getExportList(data).pipe().subscribe(success => {
          if (success) {
            //   this.isInValidNumber = true;
            this._confirmBox1.message = this._userMessages.exportListMessage;
            this._confirmBox1.isVisible = true;
          }
        })
      }
    });
  }

  exportFile(listId: any, entityType: any) {

    this._manageListService.getDynamicColumnList(listId, entityType).subscribe((resp: any) => {
      if (resp) {

        console.log(resp)
        if (resp.selectedViewColumns && resp.selectedViewColumns.viewColumns && (resp.selectedViewColumns.viewColumns || []).length > 0) {
          this.allCols = resp.selectedViewColumns?.viewColumns;
        }
        else {
          this.allCols = resp.masterViewColumns?.viewColumns;
        }

        if (resp.selectedViewColumns && resp.selectedViewColumns.columnOrder && (resp.selectedViewColumns.columnOrder || []).length > 0) {
          this.orderedCols = resp.selectedViewColumns.columnOrder;
        }
        else {
          this.orderedCols = resp.masterViewColumns.columnOrder;
        }
        this.exportListDataFile(listId, entityType)
        // this.allCols = this.columnsOfList

        // let cols: any[] = [];
        // const columns = this.grid.columns.toArray();
        // cols = columns.slice(0, 2).concat(cols);

        // if ((this.orderedCols || []).length > 0) {
        //   const columns = this.grid.columns.toArray().splice(2); // Assuming you want to start from the third column
        //   let reorderedColumns1: any[] = [];

        //   this.orderedCols.forEach((title: any) => {
        //     const column = columns.find(item => {
        //       //console.log("Title:", title);
        //       //console.log("Column Title:", item.title);
        //       return item.title === title;
        //     });
        //     if (column) {
        //       reorderedColumns1.push(column);
        //     }
        //   });
        //   cols = cols.concat(reorderedColumns1);

        //   //console.log("rC1", reorderedColumns1);
        //   this.grid.columns.reset(cols);
        //}

      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }
  //export contact list
  exportContactFile(listId: any, listName: any): void {
    let data: any = {
      listId: listId
    }
    this._manageListService.getExportList(data).pipe().subscribe((resp) => {
      if (resp) {
        this.unAthorizesd = this._userMessages.exportListMessage;
        this._confirmBox.isVisible = true;
      }
      // let downloadLink = document.createElement('a');
      // downloadLink.href = window.URL.createObjectURL(resp.body);
      // // create name dynamically
      // let cur_date = new Date();
      // let listNameWithDate: any;
      // listNameWithDate =
      //   listName + '_' +
      //   this.datepipe.transform(cur_date, 'MMddyyy_HHmm') +
      //   '.csv';

      // downloadLink.setAttribute('download', listNameWithDate);
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // downloadLink.remove();
    });
  }

  //export wba list
  exportWbaFile(listId: any, listName: any): void {
    let data: any = {
      listId: listId
    }
    this._manageListService.getExportList(data).pipe().subscribe((resp) => {
      if (resp) {
        this.unAthorizesd = this._userMessages.exportListMessage;
        this._confirmBox.isVisible = true;
      }
      // let downloadLink = document.createElement('a');
      // downloadLink.href = window.URL.createObjectURL(resp.body);
      // // create name dynamically
      // let cur_date = new Date();
      // let listNameWithDate: any;
      // listNameWithDate =
      //   listName + '_' +
      //   this.datepipe.transform(cur_date, 'MMddyyy_HHmm') +
      //   '.csv';

      // downloadLink.setAttribute('download', listNameWithDate);
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // downloadLink.remove();
    });
  }
  tabClick(btnText: any) {
    if (btnText == 'Prospect') {
      this.getManageList('All');
      this.toggleListButton = 'Prospect';
    } else if (btnText == 'Contact') {
      this.getContactManageList('All');
      this.toggleListButton = 'Contact';
    } else if (btnText == 'Whiteboard') {
      this.toggleListButton = 'Whiteboard';
      this.manageWhiteboardPaginate('All');
    }
  }

  cancel(id: any, entity: any) {
    this.listentityType = entity
    this._confirmBox.message = this._userMessages.cancelListMessage;
    this._confirmBox.isVisible = true;
    this.id = id;
  }


  deleteList() {
    if (this.listentityType == 'Prospect') {
      sessionStorage.setItem('manageListId', this.id);
      this._manageListService.deleteList(this.id).subscribe((resp) => {
        if (resp) {
          //removing list on client side
          let index = this.FinalProspectList.data.findIndex((obj: any) => obj.id === this.id
          );
          this.FinalProspectList.data.splice(index, 1);
          this.confirmBoxCancel();
          this.getManageList('All');
        }
      },
        (error) => {
          if (error.status == 403) {
            this._confirmBox.isVisible = true;
          }
          let obj = error.error.errors;
          if (obj) {
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = arr;
          }
        }
      );
    } else if (this.listentityType == 'Contact') {
      sessionStorage.setItem('manageListId', this.id);
      this._manageListService.deleteContactList(this.id).subscribe((resp) => {
        if (resp) {
          //removing list on client side
          let index = this.FinalProspectList.data.findIndex((obj: any) => obj.id === this.id
          );
          this.FinalProspectList.data.splice(index, 1);
          this.confirmBoxCancel();
          this.getManageList('All');
        }
      },
        (error) => {
          let obj = error.error.errors;
          if (obj) {
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = arr;
          }
        }
      );

    } else {
      sessionStorage.setItem('manageListId', this.id);
      this._manageListService.deleteWBAList(this.id).subscribe((resp) => {
        if (resp) {
          //removing list on client side
          let index = this.FinalProspectList.data.findIndex((obj: any) => obj.id === this.id
          );
          this.FinalProspectList.data.splice(index, 1);
          this.confirmBoxCancel();
          this.getManageList('All');
        }
      },
        (error) => {
          let obj = error.error.errors;
          if (obj) {
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = arr;
          }
        }
      );
    }
  }


  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.closeModal();
  }
  closeModal() {
    this._activeModal.close();
  }
  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  convertDateStringInFormatting(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertDateStringInFormatting(x)
        }
        else {
          if (x.field == 'createdDate' || x.field == 'expirationDate' || x.field == 'lastModifiedDate') {
            x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
          }
        }
      })
    }
    return json;
  }

  convertFormattingInDateString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertFormattingInDateString(x)
        }
        else {
          if (x.field == 'createdDate' || x.field == 'expirationDate' || x.field == 'lastModifiedDate') {
            let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate)
          }
        }
      })
    }
    return json;
  }

  //fot total count add column
  getViewWbaList(item: any) {
    this._manageListService.viewWbaListDetailsById(item.id).subscribe((resp: any) => {
      if (resp) {
        this.userListTotalCount = resp.listDetails.totalCount;
        item.totalCount = this.userListTotalCount;
        item.lastModifiedDate = resp.listDetails.lastModifiedDate;
        this.FinalProspectList.data.forEach((x: any) => {
          if (x.id == item.id) {
            x.showDynamicCount = true;
          }
        })
      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }
  onColumnReorder(event: any) {
    if (event.newIndex < 1) {
      event.preventDefault();
      return;
    }
    // Save column order to local storage when columns are reordered
    const columns = this.grid.columns.toArray().splice(1).map(x => x.title);
    console.log(columns)
    // const reorderedColumns: any = this.columnOrder.map(fieldName => {
    //   return columns.find((column: any) => column.field === fieldName);
    // }).filter(Boolean);
    //console.log(columns)
    this.reordered = true;
    console.log("oc", this.orderedCols)
    let reorderedColumns = columns.map(item => this.orderedCols.find((val: any) => val === item));
    console.log("ro", reorderedColumns)
    let columnOrder: any[] = reorderedColumns
    let columnOrder1 = this.moveElementInArray(columnOrder, event.oldIndex - 1, event.newIndex - 1);
    console.log("ro", columnOrder1)
    const title = columnOrder1
    let currentColumns = this.allCols || [];
    this.orderedCols = title
    let columnList = { viewColumns: currentColumns, columnOrder: title }

    let opts = {

      listId: this.saveAsSelectedId,
      module: "List",
      columnList: columnList,
      filterJson: this.listFilterJsonPayload
    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
      this.getDynamicColumnList();
    })


    //localStorage.setItem("columnOrder", JSON.stringify(columnOrder));
  }

  private reorderColumns() {
    const columns = this.grid.columns.toArray();
    const reorderedColumns: any = this.columnOrder.map(fieldName => {
      return columns.find((column: any) => column.field === fieldName);
    }).filter(Boolean);

    this.grid.columns.reset(reorderedColumns);
  }

  moveElementInArray(array: any, oldIndex: number, newIndex: number) {
    if (oldIndex === newIndex || oldIndex < 0 || newIndex < 0 || oldIndex >= array.length || newIndex >= array.length) {
      return; // No need to move if old and new index are the same or out of bounds
    }

    const elementToMove = array.splice(oldIndex, 1)[0]; // Remove the element from the old index
    // console.log(elementToMove)
    array.splice(newIndex, 0, elementToMove);
    //console.log(array)
    return array; // Insert the element at the new index
  }


  getDynamicColumnList() {

    this._manageListService.getDynamicColumnList(this.saveAsSelectedId, 'List').subscribe((resp: any) => {
      if (resp) {

        console.log(resp)
        this.listFilterJsonPayload = resp.filterJson;
        this.setFilterData();
        if (this.listFilterJsonPayload && this.listFilterJsonPayload != '{}') {
          this.getManageListUsingFilterStorage('')
          this.setDataUsingFilterStorage();
        }
        else {
          this.setFilterData();
          this.getManageList('All');
        }
        if (resp.selectedViewColumns && resp.selectedViewColumns.viewColumns && (resp.selectedViewColumns.viewColumns || []).length > 0) {
          this.columnsOfList = resp.selectedViewColumns.viewColumns;
        }
        else {
          this.columnsOfList = resp.masterViewColumns.viewColumns;
        }

        if (resp.selectedViewColumns && resp.selectedViewColumns.columnOrder && (resp.selectedViewColumns.columnOrder || []).length > 0) {
          this.orderedCols = resp.selectedViewColumns.columnOrder;
        }
        else {
          this.orderedCols = resp.masterViewColumns.columnOrder;
        }


        this.ColumnOrderMaster = resp.masterViewColumns.columnOrder
        resp.masterViewColumns.viewColumns.forEach((column: any) => {
          this.columnWidthMaster[column.columnName] = column.width;
        });

        this.columnsOfListMasterData = resp.masterViewColumns.viewColumns;
        this.selectResetArr = {};
        // this.saveAsSelectedId = this.selectedListId;
        if (this.filterData) {

          if (this.gridFilter?.filters?.length) this.selectResetArr["columnFilter"] = true;
          // if (this.selectedListId == 0 && this.filterValueCopy?.filters?.length) { this.selectResetArr["filter"] = true; this.isFilter1 = false }
          if (!(this.filterData && this.filterData?.sortCol == 'listName' && this.filterData.sortDir == 'asc')) this.selectResetArr["sorting"] = true;
        }
        if (this.compareVisibility(this.columnsOfList, this.columnsOfListMasterData)) this.selectResetArr["column"] = true;

        if (!this.arraysAreIdentical(this.orderedCols, this.ColumnOrderMaster)) this.selectResetArr["columnSequence"] = true;
        if (!this.areWidthsEqual(this.columnsOfList, this.columnWidthMaster)) this.selectResetArr["width"] = true;

        this.allCols = this.columnsOfList

        let cols: any[] = [];
        const columns = this.grid.columns.toArray();
        cols = columns.slice(0, 1).concat(cols);


        if ((this.orderedCols || []).length > 0) {
          const columns = this.grid.columns.toArray().splice(1); // Assuming you want to start from the third column
          let reorderedColumns1: any[] = [];

          this.orderedCols.forEach((title: any) => {
            const column = columns.find(item => {
              //console.log("Title:", title);
              //console.log("Column Title:", item.title);
              return item.title === title;
            });
            if (column) {
              reorderedColumns1.push(column);
            }
          });
          cols = cols.concat(reorderedColumns1);
          this.grid.columns.reset(cols);
        }

      }

    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }

  setFilterData() {
    if (this.listFilterJsonPayload) {
      this.filterData = JSON.parse(this.listFilterJsonPayload);

      if (this.filterData?.gridFilterJson) this.gridFilter = JSON.parse(this.filterData?.gridFilterJson);
      // if (this.filterData?.filterJson) this.filterValue = JSON.parse(this.filterDataRecall?.filterJson);
      // if (this.filterDataRecall?.filterJson) this.filterValueCopy = JSON.parse(this.filterDataRecall?.filterJson);
      if (this.filterData?.sortCol) this.sort = [{ field: this.filterData?.sortCol, dir: this.filterData.sortDir, }];
      if (this.filterData?.pageNumber) {
        this.pageNo = this.filterData != null ? this.filterData?.pageNumber : 1;
        this.skip = this.filterData != null ? (this.filterData?.pageNumber - 1) * this.filterData?.pageSize : 0;
        this.pageSize = this.filterData != null ? this.filterData?.pageSize : 100;
      }
      // if (this.filterValue.filters.length > 0) this.isFilter1 = true
    } else {
      // this.gridFilter = { ...this.defaultFilter };
      this.gridFilter = { logic: 'and', filters: [], };
      // this.filterValue = { ...this.defaultFilter };
      // this.filterValueCopy = { ...this.defaultFilter };
      this.sort = [{ field: 'listName', dir: 'asc', }];
      if (this.filterData) this.filterData.searchText = '';
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 50;
    }
  }

  onColumnMenuInit(e: any) {
    this.removeFilterForhideColumn = false;
    for (let i = 0; i < e.columns.length; i++) {
      this.gridFilter?.filters?.forEach((x: any, index: any) => {
        x?.filters?.forEach((z: any) => {
          if (z.field.toLowerCase() == e.columns[i].field.toLowerCase() && e.columns[i].hidden) {
            this.removeFilterForhideColumn = true;
            this.isInValidNumber = true;
            this._confirmBox1.message = "Hiding a column with a filter applied will clear the filter.";
            this._confirmBox1.isVisible = true;
            this.gridFilter?.filters.splice(index, 1)
            if (this.gridFilter?.filters.length == 0)
              (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
          }
        })
      })
    }
    let currentColumns = this.allCols || [];
    for (let i = 0; i < e.columns.length; i++) {
      currentColumns.forEach((col: any) => {
        if (col.columnName.toLowerCase() == e.columns[i].title.toLowerCase()) {

          col.isVisible = !col.isVisible
        }
      })
    }

    this.columnsOfList = currentColumns;
    let columnList = { viewColumns: currentColumns, columnOrder: this.orderedCols }
    let opts = {

      listId: this.saveAsSelectedId,
      module: "List",
      columnList: columnList,
      filterJson: this.listFilterJsonPayload
    }
    this.selectResetArr["column"] = true;

    if (!this.removeFilterForhideColumn) this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => { })
  }

  confirmBoxOk() {
    this._confirmBox1.isVisible = false;
    this.isInValidNumber = false;
    if (this.removeFilterForhideColumn) {
      this.skip = 0;
      this.pageNo = 1;
      this.totalCount = 0;
      this.getManageList(this.selectedListId, true)
    }
  }

  isGridColumnVisible(colName: string): boolean {

    let retVal: boolean = true;


    try {
      if (this.columnsOfList.length > 0) {
        let columnInfo = this.columnsOfList.find(x => x.columnName.toLowerCase() === colName.toLowerCase() && x.isVisible == true)
        if (columnInfo != undefined) {
          retVal = !columnInfo.isVisible;
        }
        else {
          retVal = true;

        }
      }
    }
    catch (e) {
      retVal = false
    }

    return retVal

  }
  exportGlobal(): void {
    const modalRef = this.modalService.open(GridColumnSelectionModalComponent, { modalDialogClass: 'listDialog' });
    modalRef.componentInstance.listId = this.selectedListId;
    modalRef.componentInstance.disabledCols = 'ID';
    // let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');
    // if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
    modalRef.componentInstance.allCols = this.allCols;

    modalRef.result.then((result) => {
      if (result) {
        // Data sent back from modal
        let listId = 0;
        let finalArr = ["ProspectID"];
        let arr: any = []
        arr = result.split(',');
        arr.forEach((x: any) => {
          finalArr.push(x);
        })
        if (result == '')
          finalArr = [];

        let data: any = {
          listId: 0,
          columnList: finalArr,
          entityType: 'list'
        };
        // console.log(data);

        this._manageListService.getExportListList(data).pipe().subscribe(success => {
          if (success) {
            this.isInValidNumber = true;
            this._confirmBox1.message = this._userMessages.exportListMessage;
            this._confirmBox1.isVisible = true;
          }
        })
      }
    });


    // this._manageListService.getExportList(data).pipe().subscribe((resp) => {
    //   let downloadLink = document.createElement('a');
    //   downloadLink.href = window.URL.createObjectURL(resp.body);
    //   // create name dynamically
    //   let cur_date = new Date();
    //   let listNameWithDate: any;
    //   listNameWithDate = listName + '_' + this.datepipe.transform(cur_date, 'MMddyyy_HHmm') + '.csv';
    //   downloadLink.setAttribute('download', listNameWithDate);
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   downloadLink.remove();
    // });
  }

  //Global Export for Admin/SD - 'All List'
  DownloadExportFile(exportType: any): void {
    if (exportType == 'All') {
      let finalArr = ["ListID"];
      let listId = finalArr.concat(this.selectedListId); //old
      let _orderedCols: any = []
      //removing hidden columns from orderedCols
      this.orderedCols.forEach((y: any) => {
        this.columnsOfList.forEach((x: any) => {
          if (x.isVisible) {
            if (x.columnName != "Actions" && x.columnName != "Row Count" && x.columnName != null) {
              if (y == x.columnName)
                _orderedCols.push(x.columnName);
            }
          }
        })
      })
      finalArr = finalArr.concat(_orderedCols)

      let data: any = {
        listId: this.saveAsSelectedId, //new
        columnList: finalArr,
        entityType: 'list',
        appliedFilter: this.listFilterJsonPayload
      };


      this._manageListService.getExportListList(data).pipe().subscribe(success => {
        if (success) {
          this.isInValidNumber = true;
          this._confirmBox1.message = this._userMessages.exportListMessage;
          this._confirmBox1.isVisible = true;
        }
      })
    }





  }
  userRoleChecked() {
    let userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
    if (userDetailsData) {
      userDetailsData?.user?.roles.forEach((x: any) => {
        if (x.roleName == 'Admin' || x.roleName == 'Sales Director' || x.roleName == 'Client Service Level 4') {
          this.isValidUser = true;
        }
      })
    }
  }
  setStyleOfPopup() {
    setTimeout(() => {
      const animationContainer = document.querySelector('.k-animation-container.k-animation-container-shown');
      if (animationContainer) {
        const popupElement = animationContainer as HTMLElement;
        if (popupElement) {
          if (animationContainer.childNodes[0] && (animationContainer.childNodes[0] as HTMLElement).className === 'k-popup') {
            popupElement.classList.add('columnMenuFilter_contact');
            animationContainer.querySelector('kendo-grid-columnlist')?.classList.add('d-block')
            animationContainer.querySelector('.k-column-chooser-title')?.classList.add('d-block')
          }
        }
      }

    }, 200);
  }

  resetAllfunctionality() {

    if (this.listFilterJsonPayload == '' || this.listFilterJsonPayload == undefined) this.listFilterJsonPayload = sessionStorage.getItem('listProspectFilterStorage');
    let temppayload = JSON.parse(this.listFilterJsonPayload ? this.listFilterJsonPayload : '{}');
    let value = this.selectResetArr;

    if (value['sorting']) {
      this.sort = [
        {
          field: 'listName',
          dir: 'asc',
        }
      ];

      this.skip = 0;
      this.pageNo = 1;
      temppayload.pageNumber = 1;
      temppayload.pageSize = 50;
      temppayload.skip = 0;
      this.sort = this.sort;
      temppayload.sortCol = "listName"
      temppayload.sortDir = 'asc'
      // this.totalCount = this.finalWBAList.total;
    }

    if (value["columnFilter"]) {
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 50;
      this.searchText = "";
      this.searchGrid = "";
      this.totalCount = 0;
      temppayload.searchText = "";
      temppayload.pageNumber = 1;
      temppayload.pageSize = 50;
      temppayload.skip = 0;
      this.filterData = temppayload;
      if (this.filterData) this.filterData.searchText = '';
      this.filterDataEmpty = '';
      // this.filterData ? this.filterData.searchText : this.filterDataEmpty
      this.gridFilter = { logic: 'and', filters: [], };
      temppayload.gridFilterJson = "";
      this.gridFilterArrayStatus = [];
      this.gridFilterArrayListEntityType = [];
    }


    if (value["columnSequence"]) {
      this.orderedCols = this.ColumnOrderMaster;
    }
    if (value["width"]) {
      this.columnsOfList.forEach(s => s.width = this.columnWidthMaster[s.columnName]);
    }

    if (value["column"]) {
      this.columnsOfList = this.columnsOfListMasterData;
    }
    this.listFilterJsonPayload = JSON.stringify(temppayload);
    if (this.listFilterJsonPayload == '{}') this.listFilterJsonPayload = "";
    this.saveUserMasterGridColumn(true);
    // this.getManageList(this.selectedListId, true);
    this.selectResetArr = {};
    (document.getElementById('columnSequence') as HTMLInputElement).checked = false;
    (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
    // (document.getElementById('filter') as HTMLInputElement).checked = false;
    (document.getElementById('sorting') as HTMLInputElement).checked = false;
    (document.getElementById('width') as HTMLInputElement).checked = false;
    (document.getElementById('column') as HTMLInputElement).checked = false;
    (document.getElementById('resetAll') as HTMLInputElement).checked = false;
    (document.getElementById('dropdownMenuButton3') as HTMLButtonElement).click();
    if (this.reordered) this.reloadComponent(true);
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    //skipLocationChange:true means dont update the url to / when navigating
    console.log("Current route I am on:", this.router.url);
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        console.log(`After navigation I am on:${this.router.url}`)
      })
    })
  }

  saveUserMasterGridColumn(call: boolean = false) {

    let columnList = { viewColumns: this.columnsOfList, columnOrder: this.orderedCols }
    let opts = {

      listId: this.saveAsSelectedId,
      module: "List",
      columnList: columnList,
      filterJson: this.listFilterJsonPayload,

    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
      if (call) this.getDynamicColumnList()
    })
  }

  onChangeResetAll(event: any) {
    if (event.target.checked) {
      this.selectResetArr = {
        sorting: true,
        filter: true,
        columnFilter: true,
        columnSequence: true,
        width: true,
        column: true
      };
      //this.isFilter1 = false;
      (document.getElementById('columnSequence') as HTMLInputElement).checked = true;
      (document.getElementById('columnFilter') as HTMLInputElement).checked = true;
      //(document.getElementById('filter') as HTMLInputElement).checked = true;
      (document.getElementById('sorting') as HTMLInputElement).checked = true;
      (document.getElementById('width') as HTMLInputElement).checked = true;
      (document.getElementById('column') as HTMLInputElement).checked = true;
    } else {
      (document.getElementById('columnSequence') as HTMLInputElement).checked = false;
      (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
      //(document.getElementById('filter') as HTMLInputElement).checked = false;
      (document.getElementById('sorting') as HTMLInputElement).checked = false;
      (document.getElementById('width') as HTMLInputElement).checked = false;
      (document.getElementById('column') as HTMLInputElement).checked = false;
      (document.getElementById('resetAll') as HTMLInputElement).checked = false;
      this.selectResetArr = {}
    }
  }

  arraysAreIdentical(arr1: any, arr2: any) {


    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  areWidthsEqual(columns1: any, columns2: any) {


    // Iterate over each column in the first array
    for (let i = 0; i < columns1.length; i++) {
      const columnName = columns1[i].columnName;

      // Check if the width of the column in the first array is defined
      if (columns1[i].width === undefined) {
        return false;
      }

      // Check if the width of the column in the first array is equal to the width in the second array
      if (columns1[i].width != 0 && columns1[i].width !== columns2[columnName]) {
        return false;
      }
    }
    return true;
  }

  compareVisibility(originalJson: any, updatedJson: any) {
    for (let i = 0; i < originalJson.length; i++) {
      const originalColumn = originalJson[i];
      const updatedColumn = updatedJson.find((column: any) => column.columnName === originalColumn.columnName);


      if (originalColumn.isVisible !== updatedColumn.isVisible) {
        return true;
      }
    }

    return false;
  }

  GridColumnWidth(colName: string): number {
    let width: number = 200;

    try {
      if (this.columnsOfList.length > 0) {
        let columnInfo = this.columnsOfList.find(x => x.columnName.toLowerCase() == colName.toLowerCase())
        if (columnInfo != undefined)
          width = columnInfo.width;
      }
    }
    catch (e) {
      width = 200
    }
    return width
  }

  columnResize(event: any) {
    this.selectResetArr["width"] = true;
    this.columnsOfList.forEach(x => {
      if (x.columnName.toLowerCase() == event[0].column.title.toLowerCase()) {
        x.width = event[0].newWidth;
      }
    })
    let columnList = { viewColumns: this.columnsOfList, columnOrder: this.orderedCols }
    let opts = {

      listId: this.saveAsSelectedId,
      module: "List",
      columnList: columnList,
      filterJson: this.listFilterJsonPayload,

    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
      // this.getDynamicColumnList();
    })

  }
  //for all user
  getUserList() {
    let data = {
      "lookUpType": 'AllUser',
      "rowCount": 500
    }
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((resp: any) => {
      this.userList = resp.userList;
    })
  }

}

