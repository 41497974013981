import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ManageListService } from '../../../services/ManageList.service';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridColumnSelectionModalComponent } from '../../grid-column-selection-modal/grid-column-selection-modal.component';


@Component({
  selector: 'app-preview-list',
  templateUrl: './preview-list.component.html',
  styleUrls: ['./preview-list.component.css']
})

export class PreviewListComponent implements OnInit {
  _navigationPath: NavigationPath;
  // @ViewChild("calendar", { static: false })
  overlappedProspect: any[] = [];
  splittedList: any[] = [];
  sharedUser: any[] = [];
  showError: boolean = false;
  errorMessage: string = '';
  errorList: any[] = [];
  selectedListColumn: any[] = [];
  ListDetailData: any = {};
  ListId: any = 0;
  isDisable: boolean = true;
  userList: any;
  expirationDate: any
  DateSelected: any;
  isCalendarVisible: boolean = false;
  showhide: boolean = false;
  isUpdateExpirationDate: any
  effectiveDate: any;
  toDisplay: boolean = true;
  minimumDate = new Date();
  public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  _userMessages: UserMessages;
  _confirmBox: ConfirmBox;
  unAthorizesd: any = '';
  public filters: FilterExpression[] = [];
  listId: any;
  hidden: boolean = true;
  allCols: any;
  orderedCols: any;

  // public filters: FilterExpression[] = [
  //   {
  //     field: 'ProspectName',
  //     title: 'Company Name',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'phoneNumber',
  //     title: 'Phone No',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'city',
  //     title: 'City',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'tags',
  //     title: 'Tags',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'state',
  //     title: 'State',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'prospectInformation.nonProfit',
  //     title: 'Non-Profit',
  //     editor: 'boolean'
  //   },
  //   {
  //     field: 'Revenue',
  //     title: 'Revenue',
  //     editor: 'number'
  //   },
  //   {
  //     field: 'prospectInformation.expmod',
  //     title: 'Exp. Mod',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'prospectInformation.noOfEmployees',
  //     title: '# of Employees',
  //     editor: 'number'
  //   },
  //   {
  //     field: 'prospectInformation.workersCompensationExpirationDate',
  //     title: 'WC X-Date',
  //     editor: 'date'
  //   },
  //   {
  //     field: 'prospectInformation.workersCompensationPremium',
  //     title: 'WC Prem',
  //     editor: 'number'
  //   },
  //   {
  //     field: 'prospectInformation.packageExpirationDate',
  //     title: 'Pkg X-Date',
  //     editor: 'date'
  //   },
  //   {
  //     field: 'prospectInformation.packagePremium',
  //     title: 'Pkg Prem',
  //     editor: 'number'
  //   },
  //   {
  //     field: 'finalDispo',
  //     title: 'Final Dispo',
  //     editor: 'string'
  //   },
  //   {
  //     field: 'finalDispositionDate',
  //     title: 'Final Dispo Date',
  //     editor: 'date'
  //   },
  //   {
  //     field: 'zipCode',
  //     title: 'Zip Code',
  //     editor: 'number',
  //   },
  //   {
  //     field: 'emailAddress',
  //     title: 'Email',
  //     editor: 'string',
  //   },

  //   {
  //     field: 'prospectInformation.packageCarrier',
  //     title: 'Package Carrier',
  //     editor: 'string',
  //   },
  // ];

  constructor(
    private _router: Router,
    private _manageListService: ManageListService,
    private _ProspectListService: ProspectListService,
    public datepipe: DatePipe,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal
    //private calendar: Calendar
  ) {
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();
    this._confirmBox = new ConfirmBox();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.ListId = params.listId
    })
    // this.ListId = localStorage.getItem('ListIdForDetails');
    this.getProspectFilterJSON();
  }

  public getProspectFilterJSON(): void {
    this._ProspectListService.getProspectFilterJSON().subscribe((response: any) => {
      this.filters = response.masterFilterOptionList;
      this.filters.forEach((element: any) => {
        if (
          element.field == 'ProspectInformation.NoOfEmployees' ||
          element.field == 'ProspectInformation.Revenue' ||
          element.field == 'ProspectInformation.PackagePremium' ||
          element.field == 'ProspectInformation.WorkersCompensationPremium' ||
          element.field == 'WBA.TargetPremium' ||
          element.field == 'WBAOutComeDetail.BoundRevenue'
        ) {
          element.editorFormat = 'n0'
        }
      })
      this.getViewProspectList();
    });
  }

  getViewProspectList() {
    this._manageListService.viewListDetailsById(this.ListId).subscribe((resp: any) => {
      if (resp) {
        this.expirationDate = this.datepipe.transform(resp.listDetails.expirationDate, 'MM/dd/yyyy')
        this.userList = resp.listDetails;
        this.filterValue = JSON.parse(this.userList.listFilterCriteria);
        this.filterValue = this.convertStringToNumber(this.filterValue);
        this.convertContainsIntoEquals(this.filterValue);
        this.convertStringIntoDate(this.filterValue);
        this.selectedListColumn = this.userList.selectedColumnsList;
        this.sharedUser = this.userList.sharedListUsers;
        this.overlappedProspect = this.userList.prospectOnOtherList;
        this.splittedList = this.userList.listSummary;
        this.selectedListColumn = this.userList.selectedColumnsList.filter((el: any) => {
          return el.isVisible == true;
        });
      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }

  //convert contains operator to eq operator
  convertContainsIntoEquals(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertContainsIntoEquals(x)
        }
        else {
          if (x.field === 'State' || x.field === 'WBA.MasterBusinessStatus' || x.field === 'WBAStatusId' || x.field === 'ProspectEpicStatus.EpicStatusName'
            || x.field === 'ProspectRecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
            || x.field === 'Contact.Office1State' || x.field === 'WBALine.Line' || x.field === 'PI.IndustryName' || x.field === 'ProspectIndustry.IndustryName' || x.field === 'ProfitCenter' || x.field === 'ProspectProgress.ProgressName'
            || x.field == 'wba.OriginalProducerName' || x.field == "Prospect.ModifiedBy" || x.field == "WBA.MarketingManagerId" || x.field == "Prospect.LastContactedBy" || x.field === "WBA.OwnerUserName" || x.field == 'WBA.CreatedBy' || x.field == 'Prospect.CreatedBy') {
            x.operator = 'eq';
          }
        }
      })
    }
    return json;
  }


  saveDate() {
    this.showhide = false;
    this.isCalendarVisible = false;
    this.toDisplay = true;
    let data = {
      listId: this.ListId,
      expirationDate: this.datepipe.transform(this.effectiveDate, 'MM/dd/yyyy')
    }
    this._manageListService.updateExpirationDate(data).subscribe(
      (resp) => {
        this.expirationDate = this.datepipe.transform(this.effectiveDate, 'MM/dd/yyyy')
      },
      (err: any) => {
        if (err.status !== 200) {
          this.errorList = [];
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {
            this.showError = true;
            this.errorMessage =
              'Error : ' + err.message + ', Status: ' + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }



  cancel() {
    this.showhide = false;
    this.isCalendarVisible = false
    this.toDisplay = true
  }




  closeModel() {
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
  }

  // exportFile(): void {

  //   const modalRef = this.modalService.open(GridColumnSelectionModalComponent, { modalDialogClass: 'listDialog' });
  //   modalRef.componentInstance.listId = this.userList.listId;

  //   modalRef.result.then((result) => {

  //     console.log(result); // Data sent back from modal
  //     let listId = this.userList.listId;
  //     let arr: [] = result.split(',');
  //     if (result == '')
  //       arr = [];


  //     let listName = this.userList.listName
  //     let data: any = {
  //       listId: listId,
  //       columnList: arr
  //     };
  //     // console.log(data);

  //     this._manageListService.getExportList(data).pipe().subscribe(success => {
  //       if (success) {
  //         this._confirmBox.message = this._userMessages.exportListMessage;
  //         this._confirmBox.isVisible = true;
  //       }
  //     })
  //   });


  //   // this._manageListService.getExportList(data).pipe().subscribe((resp) => {
  //   //   let downloadLink = document.createElement('a');
  //   //   downloadLink.href = window.URL.createObjectURL(resp.body);
  //   //   // create name dynamically
  //   //   let cur_date = new Date();
  //   //   let listNameWithDate: any;
  //   //   listNameWithDate = listName + '_' + this.datepipe.transform(cur_date, 'MMddyyy_HHmm') + '.csv';
  //   //   downloadLink.setAttribute('download', listNameWithDate);
  //   //   document.body.appendChild(downloadLink);
  //   //   downloadLink.click();
  //   //   downloadLink.remove();
  //   // });
  // }

  exportListDataFile(): void {

    const modalRef = this.modalService.open(GridColumnSelectionModalComponent, { modalDialogClass: 'listDialog' });
    modalRef.componentInstance.listId = this.ListId;
    let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');
    if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
    modalRef.componentInstance.allCols = this.allCols;
    modalRef.result.then((result) => {

      if (result) {

        // Data sent back from modal
        let finalArr: any[] = []

        finalArr = ["ProspectID"];
        let arr: any = []
        arr = result.split(',');
        arr.forEach((x: any) => {
          finalArr.push(x);
        })
        if (result == '')
          finalArr = [];

        let data: any = {
          listId: this.ListId,
          columnList: finalArr,
          entityType: 'Prospect'
        };
        // console.log(data);

        this._manageListService.getExportList(data).pipe().subscribe(success => {
          if (success) {
            //   this.isInValidNumber = true;
            this._confirmBox.message = this._userMessages.exportListMessage;
            this._confirmBox.isVisible = true;
          }
        })
      }
    });
  }

  exportFile() {

    this._manageListService.getDynamicColumnList(this.ListId, 'Prospect').subscribe((resp: any) => {
      if (resp) {

        console.log(resp)
        if (resp.selectedViewColumns && resp.selectedViewColumns.viewColumns && (resp.selectedViewColumns.viewColumns || []).length > 0) {
          this.allCols = resp.selectedViewColumns?.viewColumns;
        }
        else {
          this.allCols = resp.masterViewColumns?.viewColumns;
        }

        if (resp.selectedViewColumns && resp.selectedViewColumns.columnOrder && (resp.selectedViewColumns.columnOrder || []).length > 0) {
          this.orderedCols = resp.selectedViewColumns.columnOrder;
        }
        else {
          this.orderedCols = resp.masterViewColumns.columnOrder;
        }
        this.exportListDataFile();
        // this.allCols = this.columnsOfList

        // let cols: any[] = [];
        // const columns = this.grid.columns.toArray();
        // cols = columns.slice(0, 2).concat(cols);

        // if ((this.orderedCols || []).length > 0) {
        //   const columns = this.grid.columns.toArray().splice(2); // Assuming you want to start from the third column
        //   let reorderedColumns1: any[] = [];

        //   this.orderedCols.forEach((title: any) => {
        //     const column = columns.find(item => {
        //       //console.log("Title:", title);
        //       //console.log("Column Title:", item.title);
        //       return item.title === title;
        //     });
        //     if (column) {
        //       reorderedColumns1.push(column);
        //     }
        //   });
        //   cols = cols.concat(reorderedColumns1);

        //   //console.log("rC1", reorderedColumns1);
        //   this.grid.columns.reset(cols);
        //}

      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }

  openCalendar(event: any) {
    this.showhide = true
    this.isCalendarVisible = !this.isCalendarVisible;
    this.toDisplay = !this.toDisplay;
    this.effectiveDate = this.expirationDate

  }

  navigateToList() {
    sessionStorage.setItem('manageListId', this.ListId);
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditListUrl());
  }

  //convert string to Number
  convertStringToNumber(json: any) {
    if (json) {
      json.filters.forEach((element: any) => {
        if (element.hasOwnProperty('filters')) {
          this.convertStringToNumber(element);
        } else {
          if (element.field == 'ProspectInformation.NoOfEmployees' ||
            element.field == 'ProspectInformation.Revenue' ||
            element.field == 'ProspectInformation.PackagePremium' ||
            element.field == 'ProspectInformation.WorkersCompensationPremium' ||
            element.field == 'WBA.TargetPremium' ||
            element.field == 'WBAOutComeDetail.BoundRevenue' ||
            element.field == 'WbaWorkersCompensationRatingDetail.ClassCode') {
            element.value = +(element.value);
          }
        }
      });
    }
    return json;
  }
  convertStringIntoDate(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringIntoDate(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase());
          if (fieldType?.editor == 'date') {

            let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate);
          }
        }
      })
    }
    return json;
  }
}
