import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListManagementRoutingModule } from './list-management-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ManageListComponent } from './components/manage-list/manage-list.component';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { PreviewListComponent } from './components/prospect-list/preview-list/preview-list.component';
import { SelectProspectsComponent } from './components/prospect-list/select-prospects/select-prospects.component';
import { SelectColumnsComponent } from './components/prospect-list/select-columns/select-columns.component';
import { ShareProspectsComponent } from './components/prospect-list/share-prospects/share-prospects.component';
import { DialogModule } from 'primeng/dialog';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';
import {NgxMaskDirective, NgxMaskPipe} from 'ngx-mask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CreateListService } from './services/CreateList.service';
import { FilterModule } from '@progress/kendo-angular-filter';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ShareAndSaveProspectComponent } from './components/prospect-list/share-and-save-prospect/share-and-save-prospect.component';
import { CreateSplitListComponent } from './components/prospect-list/create-split-list/create-split-list.component';
import { CreateQueryComponent } from './components/prospect-list/create-query/create-query.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ShowAssignedUsersComponent } from './components/prospect-list/show-assigned-users/show-assigned-users.component';
import { UserListPreviewComponent } from './components/user-list-preview/user-list-preview.component';
import { CreateListContactsComponent } from './components/contact-list/create-list-contacts/create-list-contacts.component';
import { SelectContactColumnListComponent } from './components/contact-list/select-contact-column-list/select-contact-column-list.component';
import { ShareUserListComponent } from './components/contact-list/share-user-list/share-user-list.component';
import { ShareContactUserListComponent } from './components/contact-list/share-contact-user-list/share-contact-user-list.component';
import { PreviewContactListComponent } from './components/contact-list/preview-contact-list/preview-contact-list.component';
import { CreateListWhiteboardComponent } from './components/whiteboard-list/create-list-whiteboard/create-list-whiteboard.component';
import { SelectWhiteboardColumnListComponent } from './components/whiteboard-list/select-whiteboard-column-list/select-whiteboard-column-list.component';
import { ShareWhiteboardUserListComponent } from './components/whiteboard-list/share-whiteboard-user-list/share-whiteboard-user-list.component';
import { PreviewWhiteboardListComponent } from './components/whiteboard-list/preview-whiteboard-list/preview-whiteboard-list.component';
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { GridColumnSelectionModalComponent } from './components/grid-column-selection-modal/grid-column-selection-modal.component';

@NgModule({
  declarations: [
    ManageListComponent,
    PreviewListComponent,
    SelectProspectsComponent,
    SelectColumnsComponent,
    ShareProspectsComponent,
    CreateSplitListComponent,
    ShareAndSaveProspectComponent,
    CreateSplitListComponent,
    CreateQueryComponent,
    ShowAssignedUsersComponent,
    UserListPreviewComponent,
    CreateListContactsComponent,
    SelectContactColumnListComponent,
    ShareUserListComponent,
    ShareContactUserListComponent,
    PreviewContactListComponent,
    CreateListWhiteboardComponent,
    SelectWhiteboardColumnListComponent,
    ShareWhiteboardUserListComponent,
    PreviewWhiteboardListComponent,
    GridColumnSelectionModalComponent,
  ],
  imports: [
    CommonModule,
    ListManagementRoutingModule,
    DropdownModule,
    MultiSelectModule,
    ScrollPanelModule,
    FormsModule,
    ReactiveFormsModule,
    PaginatorModule,
    TooltipModule,
    DialogModule,
    NgbModule,
    ButtonModule,
    AutoCompleteModule,
    CalendarModule,
    FilterModule,
    GridModule,
    ButtonsModule,
    IconsModule,
    LayoutModule,
    SharedModule,
    DropDownsModule,
    InputsModule,
    LabelModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpInterceptors,
    //   multi: true
    // },
    NgbActiveModal,
    CreateListService
  ]
})
export class ListManagementModule { }
