import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageListComponent } from './components/manage-list/manage-list.component';
import { PreviewListComponent } from './components/prospect-list/preview-list/preview-list.component';
import { SelectColumnsComponent } from './components/prospect-list/select-columns/select-columns.component';
import { ShareProspectsComponent } from './components/prospect-list/share-prospects/share-prospects.component';
import { ShareAndSaveProspectComponent } from './components/prospect-list/share-and-save-prospect/share-and-save-prospect.component';
import { CreateSplitListComponent } from './components/prospect-list/create-split-list/create-split-list.component';
import { CreateQueryComponent } from './components/prospect-list/create-query/create-query.component';
import { ShowAssignedUsersComponent } from './components/prospect-list/show-assigned-users/show-assigned-users.component';
import { SelectProspectsComponent } from './components/prospect-list/select-prospects/select-prospects.component';
import { UserListPreviewComponent } from './components/user-list-preview/user-list-preview.component';
import { CreateListContactsComponent } from './components/contact-list/create-list-contacts/create-list-contacts.component';
import { SelectContactColumnListComponent } from './components/contact-list/select-contact-column-list/select-contact-column-list.component';
import { ShareUserListComponent } from './components/contact-list/share-user-list/share-user-list.component';
import { ShareContactUserListComponent } from './components/contact-list/share-contact-user-list/share-contact-user-list.component';
import { PreviewContactListComponent } from './components/contact-list/preview-contact-list/preview-contact-list.component';
import { CreateListWhiteboardComponent } from './components/whiteboard-list/create-list-whiteboard/create-list-whiteboard.component';
import { SelectWhiteboardColumnListComponent } from './components/whiteboard-list/select-whiteboard-column-list/select-whiteboard-column-list.component';
import { ShareWhiteboardUserListComponent } from './components/whiteboard-list/share-whiteboard-user-list/share-whiteboard-user-list.component';
import { PreviewWhiteboardListComponent } from './components/whiteboard-list/preview-whiteboard-list/preview-whiteboard-list.component';

const routes: Routes = [
  { path: '', component: ManageListComponent },
  { path: 'manage-list', component: ManageListComponent },
  { path: 'preview-list', component: PreviewListComponent },
  // { path: 'share-list', component: SharedListComponent },
  { path: 'select-prospects', component: SelectProspectsComponent },
  { path: 'select-columns', component: SelectColumnsComponent },
  { path: 'share-prospects', component: ShareProspectsComponent },
  { path: 'share-prospect', component: ShareAndSaveProspectComponent },
  { path: 'overlapped-prospect', component: SelectProspectsComponent },
  { path: 'split-list', component: CreateSplitListComponent },
  { path: 'edit-list', component: CreateQueryComponent },
  { path: 'create-list', component: CreateQueryComponent },
  { path: 'user-preview-list', component: UserListPreviewComponent },
  { path: 'show-assigned-user', component: ShowAssignedUsersComponent },
  { path: 'create-contacts-list', component: CreateListContactsComponent },
  { path: 'create-contact-column-list', component: SelectContactColumnListComponent },
  { path: 'create-share-user-list', component: ShareUserListComponent },
  { path: 'contact-share-user-list', component: ShareContactUserListComponent },
  { path: 'edit-list-contact', component: CreateListContactsComponent },
  { path: 'preview-list-contact', component: PreviewContactListComponent },
  { path: 'create-whiteBoard-list', component: CreateListWhiteboardComponent },
  { path: 'create-whiteBoard-column-list', component: SelectWhiteboardColumnListComponent },
  { path: 'create-whiteBoard-share-list', component: ShareWhiteboardUserListComponent },
  { path: 'edit-list-whiteboard', component: CreateListWhiteboardComponent },
  { path: 'preview-list-whiteboard', component: PreviewWhiteboardListComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListManagementRoutingModule { }
