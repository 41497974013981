export class CreateListScreen {
    listName = {
        required: 'List Name is required.',
        maxlength: 'List Name should not exceed more than 100 characters.',
        pattern: 'Please enter valid data'
    }
    saveAs = {
        required: 'Save As Type is required.',
    }
    queryName = {
        required: 'Dynamic List name is required.',
        maxlength: 'Dynamic List Name should not exceed more than 100 characters.'
    }
    filterName = {
        required: 'Filter criteria is required.',
    }


}


