import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ListDataService {
    listDataInDetails = new BehaviorSubject('');
    public listId = new BehaviorSubject('');

    setListId(val: any) {
        this.listId.next(val);
    }

    getListId() {
        return this.listId;
    }

    setListDetailedData(val: any) {
        this.listDataInDetails.next(val);
    }

    getListDetailedData() {
        return this.listDataInDetails;
    }

    constructor() { }

}
