import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ManageListService } from '../../../services/ManageList.service';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { GridColumnSelectionModalComponent } from '../../grid-column-selection-modal/grid-column-selection-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-whiteboard-list',
  templateUrl: './preview-whiteboard-list.component.html',
  styleUrls: ['./preview-whiteboard-list.component.css']
})
export class PreviewWhiteboardListComponent implements OnInit {

  _navigationPath: NavigationPath;
  overlappedProspect: any[] = [];
  splittedList: any[] = [];
  sharedUser: any[] = [];
  showError: boolean = false;
  errorMessage: string = '';
  errorList: any[] = [];
  selectedListColumn: any[] = [];
  ListDetailData: any = {};
  ListId: any = 0;
  isDisable: boolean = true;
  userList: any;
  public dateFilters: any[] = [];

  public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };


  public filters: FilterExpression[] = [];
  _userMessages: UserMessages;
  _confirmBox: ConfirmBox;
  allCols: any;
  orderedCols: any;

  constructor(
    private _router: Router,
    private _manageListService: ManageListService,
    public datepipe: DatePipe,
    private _whiteBoardActivityService: WhiteBoardActivityService,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();
    this._confirmBox = new ConfirmBox();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.ListId = params.listId
    }); this.getFilterJSON();
  }

  public getFilterJSON(): void {
    this._whiteBoardActivityService.getFilterJSON().subscribe((res: any) => {
      this.filters = res.masterFilterOptionList;
      this.filters.forEach((element: any) => {
        if (
          element.field == 'ProspectInformation.NoOfEmployees' ||
          element.field == 'ProspectInformation.Revenue' ||
          element.field == 'ProspectInformation.PackagePremium' ||
          element.field == 'ProspectInformation.WorkersCompensationPremium' ||
          element.field == 'TargetPremium' ||
          element.field == 'WBAOutComeDetail.BoundRevenue'
        ) {

          element.editorFormat = 'n0'
        }
      })
      this.getViewWbaList();
      //this.dateFilters = this.filters.filter((obj: any) => obj.editor === 'date');
    })
  }

  getViewWbaList() {
    this._manageListService.viewWbaListDetailsById(this.ListId).subscribe((resp: any) => {
      if (resp) {
        this.userList = resp.listDetails;
        this.filterValue = JSON.parse(this.userList.listFilterCriteria);
        this.filterValue = this.convertStringToNumber(this.filterValue);
        this.convertContainsIntoEquals(this.filterValue);
        this.filterValue = this.convertStringIntoDate(this.filterValue)
        this.selectedListColumn = this.userList.selectedColumnsList;
        this.sharedUser = this.userList.sharedListUsers;
        this.overlappedProspect = this.userList.prospectOnOtherList;
        this.splittedList = this.userList.listSummary;
        this.selectedListColumn = this.userList.selectedColumnsList.filter((el: any) => {
          return el.isVisible == true;
        });
      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }


  //convert contains operator to eq operator
  convertContainsIntoEquals(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertContainsIntoEquals(x)
        }
        else {
          if (x.field === 'State' || x.field === 'MasterBusinessStatus' || x.field === 'WBAStatusId' || x.field === 'EpicStatusName'
            || x.field === 'WBARecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
            || x.field === 'Office1State' || x.field === 'MasterBusinessLine' || x.field === 'ProspectIndustry.IndustryName' || x.field === 'ProgressName'
            || x.field === 'PI.IndustryName' || x.field === 'ProfitCenter' || x.field === 'WBA.OwnerUserName' || x.field == "WBA.MarketingManagerId" || x.field === 'wba.OriginalProducerName'
            || x.field == 'Prospect.ModifiedBy' || x.field == 'Prospect.LastContactedBy' || x.field === 'Prospect.CreatedBy' || x.field === 'WBA.CreatedBy') {
            x.operator = 'eq';
          }
        }
      })
    }
    return json;
  }


  closeModel() {
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
  }


  // export wba list
  // exportWbaFile() {

  //   const modalRef = this.modalService.open(GridColumnSelectionModalComponent);
  //   modalRef.componentInstance.listId = this.userList.listId;

  //   modalRef.result.then((result: any) => {
  //     let arr: [] = result.split(',');
  //     if (result == '')
  //       arr = [];
  //     let listId = this.userList?.listId;
  //     let listName = this.userList?.listName
  //     let data: any = {
  //       listId: listId,
  //       columnList: arr
  //     };

  //     this._manageListService.getExportList(data).pipe().subscribe((resp: any) => {
  //       if (resp) {
  //         this._confirmBox.message = this._userMessages.exportListMessage;
  //         this._confirmBox.isVisible = true;
  //       }
  //     });


  //   });








  // }

  exportListDataFile(): void {

    const modalRef = this.modalService.open(GridColumnSelectionModalComponent, { modalDialogClass: 'listDialog' });
    modalRef.componentInstance.listId = this.ListId;
    let indexwbaId = this.allCols.findIndex((x: any) => x.columnName == 'WBAID');
    if (indexwbaId > -1) this.allCols.splice(indexwbaId, 1);
    let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');
    if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
    modalRef.componentInstance.allCols = this.allCols;
    modalRef.result.then((result) => {

      if (result) {

        // Data sent back from modal

        let finalArr = ["ProspectID", "WBAID"]
        let arr: any = []
        arr = result.split(',');
        arr.forEach((x: any) => {
          finalArr.push(x);
        })
        if (result == '')
          finalArr = [];

        let data: any = {
          listId: this.ListId,
          columnList: finalArr,
          entityType: 'wba'
        };
        // console.log(data);

        this._manageListService.getExportList(data).pipe().subscribe(success => {
          if (success) {
            //   this.isInValidNumber = true;
            this._confirmBox.message = this._userMessages.exportListMessage;
            this._confirmBox.isVisible = true;
          }
        })
      }
    });
  }

  exportWbaFile() {

    this._manageListService.getDynamicColumnList(this.ListId, 'wba').subscribe((resp: any) => {
      if (resp) {

        console.log(resp)
        if (resp.selectedViewColumns && resp.selectedViewColumns.viewColumns && (resp.selectedViewColumns.viewColumns || []).length > 0) {
          this.allCols = resp.selectedViewColumns?.viewColumns;
        }
        else {
          this.allCols = resp.masterViewColumns?.viewColumns;
        }

        if (resp.selectedViewColumns && resp.selectedViewColumns.columnOrder && (resp.selectedViewColumns.columnOrder || []).length > 0) {
          this.orderedCols = resp.selectedViewColumns.columnOrder;
        }
        else {
          this.orderedCols = resp.masterViewColumns.columnOrder;
        }
        this.exportListDataFile();
        // this.allCols = this.columnsOfList

        // let cols: any[] = [];
        // const columns = this.grid.columns.toArray();
        // cols = columns.slice(0, 2).concat(cols);

        // if ((this.orderedCols || []).length > 0) {
        //   const columns = this.grid.columns.toArray().splice(2); // Assuming you want to start from the third column
        //   let reorderedColumns1: any[] = [];

        //   this.orderedCols.forEach((title: any) => {
        //     const column = columns.find(item => {
        //       //console.log("Title:", title);
        //       //console.log("Column Title:", item.title);
        //       return item.title === title;
        //     });
        //     if (column) {
        //       reorderedColumns1.push(column);
        //     }
        //   });
        //   cols = cols.concat(reorderedColumns1);

        //   //console.log("rC1", reorderedColumns1);
        //   this.grid.columns.reset(cols);
        //}

      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }

  navigateToList() {
    sessionStorage.setItem('manageListId', this.ListId);
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditWhiteBoardListUrl());
  }

  //convert string to Number
  convertStringToNumber(json: any) {
    if (json) {
      json.filters.forEach((element: any) => {
        if (element.hasOwnProperty('filters')) {
          this.convertStringToNumber(element);
        } else {
          if (element.field == 'ProspectInformation.NoOfEmployees' ||
            element.field == 'ProspectInformation.Revenue' ||
            element.field == 'ProspectInformation.PackagePremium' ||
            element.field == 'ProspectInformation.WorkersCompensationPremium' ||
            element.field == 'TargetPremium' ||
            element.field == 'WBAOutComeDetail.BoundRevenue' ||
            element.field == 'WbaWorkersCompensationRatingDetail.ClassCode') {
            element.value = +(element.value);
          }
        }
      });
    }
    return json;
  }
  convertStringIntoDate(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringIntoDate(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase());
          if (fieldType?.editor == 'date') {

            let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate);
          }
        }
      })
    }
    return json;
  }

}
