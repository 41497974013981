<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">
      <div class="add-hdr">
        <div class="heading">
          <h2>Shared with</h2>
        </div>
        <div class="add-act">
          <a href="javascript:void(0)" title="" (click)="closeModal()" class="close" data-bs-dismiss="modal"
            aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
      </div>
      <div class="m-4">
        <ul>
          <li *ngFor="let item of sharedUsersList; let i = index " class="link-city">
            {{ item.display_name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>