import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
@Component({
  selector: 'app-user-list-preview',
  templateUrl: './user-list-preview.component.html',
  styleUrls: ['./user-list-preview.component.css']
})
export class UserListPreviewComponent implements OnInit {
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  _navigationPath: NavigationPath;
  sharedUsersList: any;
  constructor(public activeModal: NgbActiveModal,) {

    this._confirmBox = new ConfirmBox();
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this.sharedUsersList = JSON.parse(sessionStorage.getItem("sharedUser")!);
  }
  //to calcel the process and back to prospect list screen
  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

}
