<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer px-4">
      <div class="page-hdr">
        <h2>Create List - Prospect</h2>
        <div class="flex-div align-items-start">
          <button class="btn btn-border createbt bck me-3" (click)="backButton()">
            <span><img src="assets/images/back.svg" alt="" class="plus-icon" /></span>
          </button>
          <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close" class="closebtn"><img
              src="assets/images/close.svg" alt="" /></a> -->
          <div class="es-link" style="padding:40 px ;">
            <a href="javascript:void(0)" [routerLink]="['../manage-list']" class="btn btn-outline-primary"><span><img
                  src="assets/images/back.svg" alt="" class="plus-icon" /></span>Back to
              List</a>
          </div>
        </div>
      </div>
      <div class="list-content2 height-auto mt-2">
        <div class="card">
          <div class="card-header cr-hd-flex">
            <div>
              <h3>Choose the users to assign the list</h3>
            </div>

            <div class="exp-ds">
              <span><b>List Type:</b> {{ ListDetailData.listType }}</span><span class="mx-2">|</span>
              <span><b>List Name:</b> {{ ListDetailData.listName }}</span>
              <span class="mx-2">|</span>
              <span><b>Expiration Date:</b>
                {{ ListDetailData.expirationDate | date: "MM/dd/yyyy" }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="assignment-block">
              <!-- <div class="row" *ngIf="showError">
                <div class="col-12">
                  <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                    <ul class="view-error">
                      <li>Choose one user atleast</li>
                    </ul>
                    <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                      aria-label="Close"></button>
                  </div>
                </div>
              </div> -->
              <div class="share-row mb-3" *ngFor="let item of userIds">
                <div class="share-hd flex-div">
                  <p>
                    <span class="me-1">{{ item.levelDisplayName }}</span> |
                    <span class="ms-1"># of Prospects ({{ item.prospectCount }})</span>
                  </p>
                </div>
                <div class="sel-col-text pb-0">
                  <div class="form-check form-check-inline" *ngFor="let userItem of item.users">
                    <input class="form-check-input" [attr.disabled]="
                        item.prospectCount == '0' ? 'disabled' : null
                      " type="checkbox" [checked]="userItem.isShared" (click)="shareListToUsers(userItem)"
                      id="{{userItem.display_name}}" value="option1" />
                    <label class="form-check-label" for="{{userItem.display_name}}">{{
                      userItem.display_name
                      }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-row my-2">
              <button class="btn btn-primary" (click)="backButton()">
                Back
              </button>
              <div>
                <!-- <button class="btn btn-primary me-3" (click)="closeModel()">
                  Save & Close
                </button> -->
                <button class="btn btn-primary" (click)="saveSplitList()">
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <span>expirationDate:{{ListDetailData.listFilter.expirationDate}}</span>
    <span>List Name:{{ListDetailData.listFilter.listName}}</span> -->
      </div>

      <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{ _confirmBox.message }}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
          <p-button (click)="closeModel()" label="Yes" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>