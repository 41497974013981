<div class="main-body-con">
    <div class="page-container pt-4">
        <div class="page-hdr-outer ps-4 pe-4">
            <div class="page-hdr">
                <h2>Create List - WhiteBoard</h2>
                <div class="flex-div align-items-start">
                    <button class="btn btn-border createbt bck me-3" (click)="backButton()">
                        <span><img src="assets/images/back.svg" alt="" class="plus-icon" /></span>
                    </button>
                    <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close" class="closebtn"><img
                            src="assets/images/close.svg" alt="" /></a> -->
                    <div class="es-link">
                        <a href="javascript:void(0)" [routerLink]="['../manage-list']"
                            class="btn btn-outline-primary"><span><img src="assets/images/back.svg" alt=""
                                    class="plus-icon" /></span>Back to
                            List</a>
                    </div>
                </div>
            </div>
            <div class="list-content2 mt-2">
                <div class="card">
                    <div class="card-header cr-hd-flex">
                        <div>
                            <h3>Select the users to share the list</h3>
                        </div>

                        <div class="exp-ds">
                            <span><b>List Type:</b> {{ ListDetailData.listType }}</span><span class="mx-2">|</span>
                            <span><b>List Name:</b> {{ ListDetailData.listName }}</span>
                            <!-- <span class="mx-2">|</span>
                            <span><b>Expiration Date:</b>
                                {{ ListDetailData.expirationDate | date: "MM/dd/yyyy" }}</span> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="sel-col1">
                            <div class="tab-content" id="myTabContent">
                                <!-- Prospect tab starts -->
                                <div class="tab-pane fade show active" id="prospect" role="tabpanel"
                                    aria-labelledby="prospect-tab">
                                    <div class="sel-col-text px-0 py-1">
                                        <div class="form-check form-check-inline" *ngFor="let item of userIds">
                                            <input class="form-check-input" type="checkbox"
                                                (click)="shareListToUsers(item)" [checked]="item.isShared"
                                                id="{{item.display_name}}" value="option1" />
                                            <label class="form-check-label" for="{{item.display_name}}">{{
                                                item.display_name
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-row my-2">
                            <button class="btn btn-primary" (click)="backButton()">
                                Back
                            </button>
                            <div>
                                <button class="btn btn-primary me-3" (click)="closeModel()">
                                    Save & Close
                                </button>
                                <button class="btn btn-primary" (click)="saveSharedUsers()">
                                    Publish
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
                [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
                [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                <p>{{_confirmBox.message}}</p>
                <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                    <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                    </p-button>
                    <p-button (click)="closeModel()" label="Yes" styleClass="btn btn-primary"></p-button>
                </ng-template>
            </p-dialog>
        </div>
    </div>


</div>