import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ListDataService } from 'src/app/shared/services/list-data.service';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { environment } from 'src/environments/environment';
import { CreateListService } from '../../../services/CreateList.service';

@Component({
  selector: 'app-share-and-save-prospect',
  templateUrl: './share-and-save-prospect.component.html',
  styleUrls: ['./share-and-save-prospect.component.css']
})
export class ShareAndSaveProspectComponent implements OnInit {
  // @Output() stepperEvent = new EventEmitter<string>();
  // @Output() UsersEvent = new EventEmitter<any>();

  // @Input() public ListID: number = 0;
  isSubmitted: boolean = false;
  userNames: any[] = [];
  saveType: any;
  _navigationPath: NavigationPath;
  userIds: any;
  ListID: any;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  errorList: any[] = [];
  ListDetailData: any = {};
  apiCallorNot: boolean = false;
  mode: string = 'create';
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _createListService: CreateListService,
    private _customeService: CustomNotifireService,
    private _listDataService: ListDataService,
    private _ProspectListService: ProspectListService,
    public activeModal: NgbActiveModal,
  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this.ListID = sessionStorage.getItem('manageListId');
    // this.ListID = Number(this._listDataService.getListId().value)
    this.getQueryById();
    //this.ListDetailData = this._listDataService.getListDetailedData().value
    this.getUsersByList(this.ListID);

  }

  getUsersByList(ListID: any) {
    this._createListService.getSelectedUsers(ListID).subscribe((resp: any) => {
      if (resp) {
        this.userIds = resp.listSharing.users;
      }
    },
      (error: any) => {

      })
  }

  //list detail method
  getQueryById() {
    this._ProspectListService.getQueryById(this.ListID).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
          this.ListDetailData.listType = this.ListDetailData.listType == 'QUERY' ? this.ListDetailData.listType = 'Dynamic' : ''
        }
      }),
      error: (() => {

      })
    })
  }
  //navigate method
  navigate(type: any) {
    this.saveType = type;
    if (this.saveType == 'manage-list') {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
    }
    if (this.saveType == 'create-list') {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'create-list');
    }

  }

  //Select and Unselect users for sharing list.
  shareListToUsers(item: any) {
    item.isShared = !item.isShared;
    if (this.userIds) {
      for (var i = 0; i < this.userIds.length; i++) {
        if (this.userIds[i] == item.userId) {
          this.userIds = this.userIds.map((i: any) => {
            return {
              userId: item.userId,
              isShared: item.isShared
            }
          });
        }
      }
    }
    console.log(this.userIds);

    // this.UsersEvent.emit(this.userIds);
  }

  //save the users
  saveSharedUsers(saveType: any) {
    this.saveType = saveType
    this.errorList = []
    let data = {
      listId: this.ListID,
      users: this.userIds
    }

    data.users.forEach((element: any) => {
      if (element.isShared == true) {
        this.apiCallorNot = true;
        return;
      }
    })


    // this._createListService.saveSharedUsers(data).subscribe((resp: any) => {
    //   if (resp) {
    //     this._createListService.publishList(this.ListID).subscribe((resp: any) => {
    //       if (resp) {
    //         this.navigate(this.saveType)
    //       }
    //     })

    //   }
    // })
    if (this.apiCallorNot == true) {
      this._createListService.saveSharedUsers(data).subscribe((resp: any) => {
        if (this.errorList.length == 0) {
          sessionStorage.removeItem('manageListId');
          this.navigate(this.saveType)
        }
        if (resp) {
          //this.ListID = resp;
          //calling publish api
          this._createListService.publishList(this.ListID).subscribe((resp: any) => {
            if (resp) {

              this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
            }
          })
        }
      },
        (error: any) => {
          let obj = error.error.errors;
          if (obj) {
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = arr;
            return
          }
        }
      )
    } else {
      sessionStorage.removeItem('manageListId');
      this._createListService.publishList(this.ListID).subscribe((resp: any) => {
        if (resp) {
          this.navigate(this.saveType)
        }
      })
    }
  }

  backButton() {
    this.mode = sessionStorage.getItem('editMode')!;
    if (this.mode == null) {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementCreateListUrl());
      sessionStorage.removeItem('editMode')
    }
    else {
      this._router.navigateByUrl('/' + this._navigationPath.getListManagementEditListUrl());
      sessionStorage.removeItem('editMode')
    }
  }

  closeModel() {
    this.errorList = []
    let data = {
      listId: this.ListID,
      users: this.userIds
    }
    data.users.forEach((element: any) => {
      if (element.isShared == true) {
        this.apiCallorNot = true;
        return;
      }
    })
    if (this.apiCallorNot == true) {
      this._createListService.saveSharedUsers(data).subscribe((resp: any) => {
        if (resp) {
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
      })
    } else {

      this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');

    }
  }

  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }
}
