<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4" *ngIf="userList">
      <div class="page-hdr ">
        <h2>{{userList.listName}}</h2>
        <div class="flex-div">
          <a *ngIf="(userList.creationStatus!='Published' && userList.creationStatus!='System-Default') ||
           (userList.creationStatus == 'Published' && userList.listType == 'QUERY') " href="javascript:void(0)"
            (click)="navigateToList()" class="pe-3">
            <span class="pe-2"><img src="assets/images/edit.svg" alt="" width="13px" /></span><label>Edit
            </label></a>

          <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close" class="closebtn"><img
              src="assets/images/close.svg" alt="" /></a> -->
          <div class="es-link">
            <a href="javascript:void(0)" [routerLink]="['../manage-list']" class="btn btn-outline-primary"><span><img
                  src="assets/images/back.svg" alt="" class="plus-icon" /></span>Back to
              List</a>
          </div>

        </div>
      </div>
      <div class="list-detail ">
        <div class="flex-div space-between">
          <div class="flex-div list-des">
            <div class="bl2"><span><img src="assets/images/user-1.png" alt="Created By"><label>Created By:</label>
                {{userList.createdBy}} </span>

            </div>
            <div class="bl2"><span><img src="assets/images/cal.png" alt="Created Pn"><label>Created On:</label>
                {{this.datepipe.transform(userList.createdDate, 'MM/dd/yyyy') }}</span>
            </div>


            <div class="bl2" *ngIf="userList.creationStatus!='Template' && userList.creationStatus!='System-Default'">
              <span><img src=" assets/images/listtype.png" alt="List Type"><label>List Type:</label>
                {{ userList.listType === 'STATIC' ? 'Static' : 'Dynamic' }} -
                {{ userList.creationStatus }}
              </span>
            </div>
            <div class="bl2" *ngIf="userList.creationStatus=='Template'">
              <span><img src=" assets/images/listtype.png" alt="List Type"><label>List Type:</label>
                {{userList.creationStatus}} </span>
            </div>
            <div class="bl2" *ngIf="userList.creationStatus=='System-Default'">
              <span><img src=" assets/images/listtype.png" alt="List Type"><label>List Type:</label>
                {{userList.creationStatus}} </span>
            </div>
            <!-- <div class="bl2" *ngIf="userList.expirationDate!=null">
              <span><img src="assets/images/cal.png" alt="Expiration Date:"><label>Expiration
                  Date:</label>{{this.datepipe.transform(userList.expirationDate, 'MM/dd/yyyy')}} </span>

            </div> -->

            <div class="bl2 flex-div bdr-left" *ngIf="userList.expirationDate!=null">
              <span><img src="assets/images/cal.png" alt="Expiration Date:"><label>Expiration
                  Date:</label><span *ngIf="!isCalendarVisible" [(ngModel)]="expirationDate"
                  class="datelabel">{{expirationDate}}</span>
                <span *ngIf="isCalendarVisible==true" class="date-control">
                  <div class="date-control-view wba-date ">
                    <p-calendar class="edit-control" id="dateList" [showIcon]="true" [iconDisplay]="'input'" [minDate]="minimumDate"
                      [readonlyInput]="true" placeholder="MM/DD/YYYY" dateFormat="mm/dd/yy" inputId="effectiveDate"
                      [(ngModel)]="effectiveDate">
                    </p-calendar>
                  </div>

                </span>

                <div class="flex-div no-img-class">
                  <div *ngIf="toDisplay">
                    <img src="assets/images/edit.svg" alt="Edit Date" (click)="openCalendar($event)"
                      style="width: 15px; position: relative; top: 2px; margin-right:.1rem">
                  </div>
                  <div *ngIf="showhide" class="withDate">
                    <img src="assets/images/save.svg" alt="Save Date" (click)="saveDate()" style="width: 12px;">
                    <img src="assets/images/cancel.svg" alt="Cancel Date" (click)="cancel()"
                      style="width: 11px; margin-right:.2rem">
                  </div>
                </div>
              </span>





            </div>


          </div>

          <div class="flex-div">
            <a href="javascript:void(0)" [ngClass]="{'isDisabled': userList.totalCount == 0}" (click)="exportFile()"
              class="flex-div btn btn-outline-primary">
              <img src="assets/images/download.svg" alt="" width="12px" /><label class="ps-2">Download</label></a>
          </div>
        </div>

      </div>


      <div class="preview-detail-view">
        <div class="card">
          <div class="card-header">
            <h3>List filter criteria</h3>
          </div>
          <div class="card-body">
            <kendo-filter #filter [filters]="filters" [value]="filterValue" [attr.disabled]="isDisable"
              id="card-filter">
            </kendo-filter>
          </div>
        </div>
        <!-- <div class="card">
          <div class="card-header">
            <h3>Selected columns</h3>
          </div>

          <div class="card-body">
            <div class="row mt-2">
              <div class="col-12" *ngIf="selectedListColumn.length==0">
                <div class="flex-div justify-content-center align-item-center nodata">
                  <p *ngIf="userList.listType == 'STATIC' ">No Data Available</p>
                  <p *ngIf="userList.listType == 'QUERY' ">This is dynamic list. All columns will be visible to all
                    shared users.</p>
                </div>
              </div>
              <ng-container *ngFor="let item of selectedListColumn">
                <div class="col-md-3">
                  <ul class="colum-ui">
                    <li>
                      {{item}}
                    </li>
                  </ul>
                </div>
              </ng-container>
            </div>
          </div>

        </div> -->

        <div class="card" *ngIf="userList.isParent==true">
          <div class="card-header">
            <h3>Shared with {{userList.creationStatus=='Draft'?' - Unpublished':''}}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <ng-container *ngFor="let item of sharedUser">
                <div class="col-12 col-sm-12 col-md-3">
                  <ul class="colum-ui-user">
                    <li>
                      <div class="role-img">
                        <span>{{item.display_name.charAt(0)}}</span>
                      </div>
                      <p class="role-name">
                        {{item.display_name}}
                        <span>{{item.position}}</span>
                      </p>
                    </li>
                  </ul>

                </div>
              </ng-container>
              <div class="col-12" *ngIf="sharedUser.length==0">
                <div class="flex-div justify-content-center align-item-center nodata">
                  <p>No Data Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="userList.isParent==true">
          <div class="card-header">
            <h3>Assigned to {{userList.creationStatus=='Draft'?' - Unpublished':''}}</h3>
          </div>

          <div class="card-body">
            <div class="row">
              <div *ngFor="let item of splittedList" class="col-12 col-sm-12 col-md-4 col-lg-3">
                <ul class="multi-select-list">
                  <li class="selected-item">
                    {{item.listName}} ({{item.noOfProspect}})
                  </li>
                </ul>
              </div>
              <div class="col-12" *ngIf="splittedList.length==0">
                <div class="flex-div justify-content-center align-item-center nodata">
                  <p>No Data Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="display: none;">
        <div class="card-header">
          <h3>Prospects in other list</h3>
        </div>
        <div class="card-body">
          <ng-container *ngFor="let item of overlappedProspect">
            <div class="row-overlap">
              <div class="flex-div row-over">
                <p>{{item.prospectName}}</p>
                <p><label># of List:</label> {{item.noOfList}}</p>
              </div>
              <ng-container>

                <div class="row">
                  <div *ngFor="let existList of item.existList" class="col-12 col-sm-12 col-md-4 col-lg-3">
                    <ul class="multi-select-list">
                      <li class="selected-item">
                        {{existList.listName}}
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-container>

            </div>
          </ng-container>
        </div>
      </div>
      <div class="button-sec">
        <button class="btn btn-primary" (click)="closeModel()">Cancel</button>
      </div>

      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{ _confirmBox.message }}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <ng-container>
            <p-button (click)="_confirmBox.isVisible = false" label="OK" styleClass="btn btn-primary"></p-button>
          </ng-container>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>
