<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4">
      <div class="page-hdr">
        <h2>Create List - Prospect</h2>
        <div class="flex-div align-items-start">
          <button class="btn btn-border createbt bck me-3" (click)="backButton()">
            <span><img src="assets/images/back.svg" alt="" class="plus-icon" /></span>
          </button>
          <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close" class="closebtn"><img
              src="assets/images/close.svg" alt="" /></a> -->
          <div class="es-link">
            <a href="javascript:void(0)" [routerLink]="['../manage-list']" class="btn btn-outline-primary"><span><img
                  src="assets/images/back.svg" alt="" class="plus-icon" /></span>Back to
              List</a>
          </div>
        </div>
      </div>
      <div *ngIf="isOneColumnSelected" class="mid-con mt-3">
        <span class="star-color">
          Select at least one column.
        </span>
      </div>
      <div class="list-content2 mt-2">
        <div class="card">
          <div class="card-header cr-hd-flex">
            <div>
              <h3>Choose the column name from given options</h3>
            </div>
            <div class="exp-ds">
              <span><b>List Type:</b> {{ ListDetailData.listType }}</span><span class="mx-2">|</span>
              <span><b>List Name:</b> {{ ListDetailData.listName }}</span>
              <span class="mx-2">|</span>
              <span><b>Expiration Date:</b>
                {{ ListDetailData.expirationDate | date: "MM/dd/yyyy" }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="sel-col1">
              <div class="sel-col-hd flex-div space-between" style="display: none">
                <p class="bold">Choose the column name from given options</p>
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                  <li style="visibility: hidden" class="nav-item">
                    <button class="nav-link active" id="prospect-tab" data-bs-toggle="tab" data-bs-target="#prospect"
                      (click)="tabChange(TabNames.prospect)" type="button" role="tab" aria-controls="prospect"
                      aria-selected="true">
                      Prospect
                    </button>
                  </li>

                  <li style="display: none" class="nav-item">
                    <button class="nav-link" id="wba-tab" data-bs-toggle="tab" data-bs-target="#wba"
                      (click)="tabChange(TabNames.wba)" type="button" role="tab" aria-controls="wba"
                      aria-selected="false">
                      Whiteboard Activities
                    </button>
                  </li>

                  <li style="display: none" class="nav-item">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                      (click)="tabChange(TabNames.contact)" type="button" role="tab" aria-controls="contact"
                      aria-selected="false">
                      Contacts
                    </button>
                  </li>
                </ul>
                <div class="search py-2 src-wd">
                  <div class="nyc-search1">
                    <input type="search" [(ngModel)]="search" class="srch-input" placeholder="Search" />
                    <input type="submit" (click)="searchColumns()" class="btn btn-sbt" value="" />
                  </div>
                </div>
              </div>

              <div class="tab-content" id="myTabContent">
                <!-- Prospect tab starts -->
                <div class="tab-pane fade show active" id="prospect" role="tabpanel" aria-labelledby="prospect-tab">
                  <div class="row mt-2">
                    <ng-container *ngFor="let item of columnNames.prospect">
                      <div class="col-md-3">
                        <div class="sel-col-text px-0 py-1">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" (click)="excludeColumnsFromList(item)"
                              [checked]="item.isVisible" id="{{
                                item.columnName
                                }}" value="option1" />
                            <label class="form-check-label" for="{{
                              item.columnName
                              }}">{{
                              item.columnName
                              }}</label>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- Prospect tab ends -->

                <!-- WhiteBoard Activity tab starts -->
                <div class="tab-pane fade" id="wba" role="tabpanel" aria-labelledby="wba-tab">
                  <div class="sel-col-text">
                    <div class="form-check form-check-inline" *ngFor="let item of columnNames.wba">
                      <input class="form-check-input" type="checkbox" [checked]="item.isVisible" id="p-name"
                        value="option1" />
                      <label class="form-check-label" for="p-name">{{
                        item.columnName
                        }}</label>
                    </div>
                  </div>
                </div>
                <!-- WhiteBoard Activity tab ends -->

                <!-- Contact tab starts -->
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <div class="sel-col-text">
                    <div class="form-check form-check-inline" *ngFor="let item of columnNames.contact">
                      <input class="form-check-input" type="checkbox" [checked]="item.isVisible" id="{{
                        item.columnName
                        }}" value="option1" />
                      <label class="form-check-label" for="{{
                        item.columnName
                        }}">{{
                        item.columnName
                        }}</label>
                    </div>
                  </div>
                </div>
                <!-- Contact tab ends -->
              </div>
            </div>
            <div class="btn-row my-2">
              <button class="btn btn-primary" (click)="backButton()">
                Back
              </button>
              <div>
                <button class="btn btn-primary me-3" (click)="closeModel()">
                  Save & Close
                </button>
                <button class="btn btn-primary" (click)="saveSelectedColumns('next')">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{_confirmBox.message}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
          <p-button (click)="closeModel()" label="Yes" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>