<!-- <div class="main-body-con"> -->
<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer px-4">
      <div class="page-hdr">
        <h2>Create List - Prospect</h2>
        <div class="flex-div align-items-start">
          <button class="btn btn-border createbt bck me-3" (click)="backButton()">
            <span><img src="assets/images/back.svg" alt="" class="plus-icon" /></span>

          </button>
          <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close" class="closebtn"><img
              src="assets/images/close.svg" alt="" /></a> -->
          <div class="es-link">
            <a href="javascript:void(0)" [routerLink]="['../manage-list']" class="btn btn-outline-primary"><span><img
                  src="assets/images/back.svg" alt="" class="plus-icon" /></span>Back to
              List</a>
          </div>
        </div>
      </div>
      <div class="list-content2 mt-2">
        <!-- <h2 class="list-hd">Prospects on Multiple Lists</h2> -->
        <div class="card">
          <div class="card-header cr-hd-flex">
            <div>
              <h3>Prospects associated with other list</h3>
            </div>

            <div class="exp-ds">
              <span><b>List Type:</b> {{ ListDetailData.listType }}</span><span class="mx-2">|</span>
              <span><b>List Name:</b> {{ ListDetailData.listName }}</span>
              <span class="mx-2">|</span>
              <span><b>Expiration Date:</b>
                {{ ListDetailData.expirationDate | date: "MM/dd/yyyy" }}</span>
            </div>
          </div>


          <div class="card-body">
            <div class="action-panel">
              <div class="nyc-input-con">


                <div class="nyc-search" style="width:400px">
                  <input type="search" placeholder="Search" [(ngModel)]="searchText" (ngModelChange)="filterSearch()"
                    class="form-control nyc-srch mx-0">
                </div>

              </div>
              <div class="action-button">
                <p-paginator (onPageChange)="onPageChange($event)" [first]="first"
                  [rows]="_paginator.rowsPerPageOptions[0]" [totalRecords]="_paginator.totalRecords"
                  [rowsPerPageOptions]="_paginator.rowsPerPageOptions">
                </p-paginator>


              </div>


            </div>
            <div class="table-responsive table-responsive-md pros-ov">
              <table class="table">
                <thead class="table-dark">
                  <tr class="table-hd">
                    <th width="3%" class="text-center">&nbsp;</th>
                    <th width="50%">Company Name</th>
                    <th width="25%"># of Lists</th>
                    <!-- <th>Name of Lists</th> -->
                    <th width="23%">
                      <div class="flex-div chk-all">
                        <div class="form-check">
                          <input class="form-check-input" (click)="changeAllSelection()"
                            [(ngModel)]="isAllProspectSelected" type="checkbox" value="" id="checkall" />
                        </div>
                        Exclude?
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <ng-container *ngFor="let prospect of OverlappedProspectsList; let i = index">
                    <tr>
                      <!-- (click)="prospect.isExpand = !prospect.isExpand" -->
                      <td (click)="isEXP(prospect)" class="text-center">
                        <ng-container *ngIf="prospect.isExpand; else expandblock;">
                          <img src="assets/images/minus.png" alt="" width="12px" class="min-pos">
                        </ng-container>

                        <ng-template #expandblock>
                          <img src="assets/images/plus.svg" alt="">
                        </ng-template>
                      </td>
                      <td class="over-text">{{ prospect.prospectName }}</td>
                      <td class="link-city">{{ prospect.noOfList }}</td>

                      <td class="chk-all">
                        <div class="form-check">
                          <input class="form-check-input" (click)="excludeProspectsFromList(prospect)" type="checkbox"
                            [(ngModel)]="prospect.isExclude" [checked]="prospect.isExclude" id="prospectCheck" />
                        </div>
                      </td>

                    </tr>
                    <tr>
                      <td colspan="4" *ngIf="prospect.isExpand" class="expanded">
                        <div class="expanded-text">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-3 "
                              *ngFor="let item of ListPopUpDetailData;let i = index">
                              <div class="bgd">
                                <ul>
                                  <li class="link-city">
                                    <span pTooltip="{{ item.listName }}" tooltipPosition="bottom"
                                      tooltipStyleClass="tooltipstyle"> {{ item.listName }}
                                      {{this.datepipe.transform(item.expirationDate, 'MM/dd/yyyy') }}
                                      {{item.createdBy}}</span>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>



                  <tr class="noRecordMessage" *ngIf="
                      OverlappedProspectsList &&
                      OverlappedProspectsList.length == 0
                    ">
                    <td colspan="17">
                      <p>No Records Found</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-row my-2">
              <button class="btn btn-primary" (click)="backButton()">
                Back
              </button>

              <div>
                <button class="btn btn-primary me-3" (click)="closeModel()">
                  Save & Close
                </button>
                <button class="btn btn-primary" (click)="saveOverlappedProspects()">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>





        <!-- <div class="list-fix1">
          <div class="subhd-cr flex-div space-between mt-0">
            <div><h3>Prospects associated with other list</h3></div>

            <div class="exp-ds">
              <span><b>List Type:</b> {{ ListDetailData.ListType }}</span
              ><span class="mx-2">|</span>
              <span><b>List Name:</b> {{ ListDetailData.listName }}</span>
              <span class="mx-2">|</span>
              <span
                ><b>Expiration Date:</b>
                {{ ListDetailData.expirationDate | date: "MM/dd/yyyy" }}</span
              >
            </div>
          </div>

          <div class="table-responsive table-responsive-md pros-ov">
            <table class="table">
              <thead class="table-dark">
                <tr class="table-hd">
                  <th>Company Name</th>
                  <th># of Lists</th>
                  <th>Name of Lists</th>
                  <th class="flex-div chk-all">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        (click)="changeAllSelection()"
                        [(ngModel)]="isAllProspectSelected"
                        type="checkbox"
                        value=""
                        id="checkall"
                      />
                    </div>
                    Exclude?
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let prospect of OverlappedProspectsList;
                    let i = index
                  "
                >
                  <td class="over-text">{{ prospect.prospectName }}</td>
                  <td class="link-city">{{ prospect.noOfList }}</td>
                  <td class="v-list">
                    <span>View List Names</span>
                    <div class="list-block-con">
                      <p>
                        <a href="#" title=""></a>The prospect has been found on
                        these lists:
                      </p>
                      <ul>
                        <li
                          *ngFor="let item of prospect.existList; let i = index"
                          class="link-city"
                        >
                          {{ item.listName }}
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="chk-all">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        (click)="excludeProspectsFromList(prospect)"
                        type="checkbox"
                        [(ngModel)]="prospect.isSelected"
                        id="prospectCheck"
                      />
                    </div>
                  </td>
                </tr>
                <tr
                  class="noRecordMessage"
                  *ngIf="
                    OverlappedProspectsList &&
                    OverlappedProspectsList.length == 0
                  "
                >
                  <td colspan="17">
                    <p>No Records Found</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn-row my-2">
            <button class="btn btn-primary" (click)="backButton()">Back</button>
            <button class="btn btn-primary" (click)="saveOverlappedProspects()">
              Next
            </button>
          </div>
        </div> -->
      </div>
      <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{_confirmBox.message}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
          <p-button (click)="closeModel()" label="Yes" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>