import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WhiteBoardListService {
  private _publishWbaListByIdUrl = `${environment.BaseUrl}/api/ListWBA/PublishList?listId=`;

  constructor(
    private _http: HttpClient
  ) { }

  publishWbaList(listId: number) {
    return this._http.put(this._publishWbaListByIdUrl + listId, {
    }).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }
}
