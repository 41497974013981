<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer px-4">
      <div class="page-hdr">
        <h2>Create List - Prospect</h2>
        <!-- <button class="btn btn-border createbt bck" (click)="backButton()">
          <span><img src="assets/images/back.svg" alt="" class="plus-icon" /></span>
        </button> -->
      </div>
      <div class="list-content2 mt-2">
        <!-- <span>expirationDate:{{ListDetailData.listFilter.expirationDate}}</span>
        <span>List Name:{{ListDetailData.listFilter.listName}}</span> -->

        <div class="card">
          <div class="card-header cr-hd-flex">
            <div>
              <h3>Published - List assigned to the users</h3>
            </div>
            <div class="exp-ds">
              <span><b>List Type:</b> {{ ListDetailData.listType }}</span><span class="mx-2">|</span>
              <span><b>List Name:</b> {{ ListDetailData.listName }}</span>
              <span class="mx-2">|</span>
              <span><b>Expiration Date:</b>
                {{ ListDetailData.expirationDate | date: "MM/dd/yyyy" }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive table-responsive-md pros-ov">
              <table class="table">
                <thead class="table-dark">
                  <tr class="table-hd">
                    <th>List Name</th>
                    <th>Assigned Users</th>
                    <th># of Prospects</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of users; let i = index">
                    <td class="over-text">{{ user.listName }}</td>
                    <td class="link-city">{{ user.displayName }}</td>
                    <td class="link-city">{{ user.noOfProspect }}</td>
                  </tr>
                  <tr class="noRecordMessage" *ngIf="users && users.length == 0">
                    <td colspan="17">
                      <p>No Records Found</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-row my-2">
              <button class="btn btn-primary" (click)="backButton()">
                Back
              </button>
              <button class="btn btn-primary" (click)="close()">
                Manage List
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>