import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ListDataService } from 'src/app/shared/services/list-data.service';
import { environment } from 'src/environments/environment';
import { CreateListService } from '../../../services/CreateList.service';

@Component({
  selector: 'app-show-assigned-users',
  templateUrl: './show-assigned-users.component.html',
  styleUrls: ['./show-assigned-users.component.css']
})
export class ShowAssignedUsersComponent implements OnInit {
  @Input() public ListID: any;
  userIds: any[] = [];
  listIdFromLocalStorage: any;
  users: any;
  ListDetailData: any = {};
  _navigationPath: NavigationPath;
  getListId: any;

  constructor(
    private _createListService: CreateListService,
    private _router: Router,
    private _listDataService: ListDataService,
    private _ProspectListService: ProspectListService
  ) {
    this._navigationPath = new NavigationPath();
  }

  ngOnInit(): void {
    this.ListID = sessionStorage.getItem('listId')
    //this.ListID = Number(this._listDataService.getListId().value)
    this.getQueryById();
    //this.ListDetailData = this._listDataService.getListDetailedData().value
    this.getSelectedAssignUsers();

  }
  async getSelectedAssignUsers() {
    try {
      let response = await this._createListService.getSelectedAssignUsers(this.ListID).toPromise();
      this.users = response.listSummary
    }
    catch (e) {
      // result = e.MessageUps;
    }
  }

  //list detail method
  getQueryById() {
    this._ProspectListService.getQueryById(this.ListID).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
        }
      }),
      error: (() => {

      })
    })
  }


  close() {
    sessionStorage.removeItem('manageListId');
    // this._createListService.publishList(this.ListID).subscribe((resp: any) => {
    //   if (resp) {
    //     this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
    //   }
    // })
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');

  }

  backButton() {
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'split-list');
  }
}
